import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import kitchen1 from "assets/img/kitchen1.jpg";
import bedroom from "assets/img/bedroom.jpg";
import living from "assets/img/living.jpg";
import balcony from "assets/img/balcony.jpg";
import IndexNavbar from "components/Navbars/IndexNavbar";
import asset1 from "assets/img/asset1.jpg";
import paradise2 from "assets/img/paradise2.jpg";
import assets3 from "assets/img/asset2.jpg";
import project4 from "assets/img/project4.jpg";
import site from "assets/img/site.jpg";
import construction from "assets/img/construction.jpg";
import villa1 from "assets/img/villa1.jpeg";
import Footer from "components/Footers/Footer";


const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
            onClick={onClick}
        >

            <span>{"<"}</span>
        </div>
    );
};

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
            onClick={onClick}
        >

            <span>{">"}</span>
        </div>
    );
};

const ProjectSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        centerPadding: "10px",
        speed: 1300,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2.25,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.02,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],

    };

    return (

        <section>
            <IndexNavbar />
            <div className="xl:mt-16 lg:mt-22 md:mt-20 sm:mt-16 mt-16">
                {/* <div className=" ">
                    <div className="container mx-auto relative text-blue xl:px-16 px-4 xl:py-4 lg:py-4 sm:py-4 py-1">
                        <a href="index" className="link text-ivory-black">
                            Home {"  "} 
                        </a>
                        {"  "}― {"  "}
                        <a href="/project-homepage" className="link">
                            Project {"  "}
                        </a>
                        
                    </div>
                </div> */}
            </div>


            <section
                className="bg-grey-50 overflow-x-auto px-4 xl:px-0 xl:pb-32 pb-16 "
                style={{ overflowX: "hidden" }}
            >
                <Slider {...settings}>

                    <div className="xl:mx-2 md:mx-2  ">
                        <div class="xl:px-2 lg:px-2 md:px-2 sm:px-1 px-1">
                            <div class="image-container">
                                <a href="/project-page">
                                    <img src="https://i.ibb.co/KWJJvvf/pruthvihome.jpg" class="xl:h-400 lg:h-330 md:h-240 xl:w-520 lg:w-520 md:w-360 w-380 h-280"></img>
                                </a>
                            </div>
                            <div className="hometext xl:mt-4 lg:mt-4 md:mt-2 mt-2 xl:ml-2 xl:ml-2 lg:ml-2 md:ml-2 ml-2 font-semibold xl:text-xl lg:text-3xl md:text-2xl text-xl">
                                Pruthvi Homes
                            </div>
                        </div>
                    </div>

                    <div className="md:mx-2 xl:mx-2">
                        <div class="xl:px-2 lg:px-2 md:px-2 sm:px-1 px-1 ">
                            <div class="image-container">
                                <a href="/project-page2">
                                    <img src="https://i.ibb.co/1fqvHQh/Pruthivi-pardise-crd.png" class="xl:h-400 lg:h-330 md:h-240 xl:w-520 lg:w-520 md:w-360 w-380 h-280"></img>
                                </a>
                            </div>
                            <div className="hometext xl:mt-4 lg:mt-4 md:mt-2 mt-2 xl:ml-2 xl:ml-2 lg:ml-2 md:ml-2 ml-2 font-semibold xl:text-xl lg:text-3xl md:text-2xl text-xl">
                                Pruthvi Paradise
                            </div>
                        </div>
                    </div>


                    <div className="md:mx-2 xl:mx-2">
                        <div class="xl:px-2 lg:px-2 md:px-2 sm:px-1 px-1">
                            <div class="image-container">
                                <a href="/project-page1">
                                    <img src="https://i.ibb.co/93GVvKP/Pruthivi-assets-crd.png" class="xl:h-400 lg:h-330 md:h-240 xl:w-520 lg:w-520 md:w-360 w-380 h-280"></img>
                                </a>
                            </div>
                            <div className="hometext xl:mt-4 lg:mt-4 md:mt-2 mt-2 xl:ml-2 xl:ml-2 lg:ml-2 md:ml-2 ml-2 font-semibold xl:text-xl lg:text-3xl md:text-2xl text-xl">
                                Pruthvi Assets
                            </div>
                        </div>
                    </div>

                    <div className="md:mx-2 xl:mx-2">
                        <div class="xl:px-2 lg:px-2 md:px-2 sm:px-1 px-1 ">
                            <div class="image-container">
                                <a href="/project-page3">
                                    <img src="https://i.ibb.co/LYMbcB1/pruthvi-2.jpg" class="xl:h-400 lg:h-330 md:h-240 xl:w-520 lg:w-520 md:w-360 w-380 h-280"></img>
                                </a>
                            </div>
                            <div className="hometext xl:mt-4 lg:mt-4 md:mt-2 mt-2 xl:ml-2 xl:ml-2 lg:ml-2 md:ml-2 ml-2 font-semibold xl:text-xl lg:text-3xl md:text-2xl text-xl">
                                Pruthvi Prime
                            </div>
                        </div>
                    </div>

                    <div className="md:mx-2 xl:mx-2">
                        <div class="xl:px-2 lg:px-2 md:px-2 sm:px-1 px-1 ">
                            <div class="image-container">
                                <img src="https://i.ibb.co/wQ5QYDY/pruthviimages.jpg" class="construction-image xl:h-400 lg:h-330 md:h-240 xl:w-520 lg:w-520 md:w-360 w-380 h-280"></img>
                                {/* <div className="overlay-text2 font-semibold xl:text-2xl"> Under Construction
                                </div> */}
                            </div>
                            <div className="hometext xl:mt-4 lg:mt-4 md:mt-2 mt-2 xl:ml-2 xl:ml-2 lg:ml-2 md:ml-2 ml-2 font-semibold xl:text-xl lg:text-3xl md:text-2xl text-xl">
                                Coming Soon...
                            </div>
                        </div>
                    </div>

                </Slider>
            </section>

            <section className="container overflow-hidden mx-auto xl:px-16 px-4 xl:mb-12 lg:mb-12 md:mb-12 ">
                <div class="construction-container mb-8">
                    <img src="https://i.ibb.co/xqcrLfM/coming-soon-villas.png" class="rounded-md w-full h-200"></img>
                    <div className="overlay-text2 font-bold xl:text-3xl -mt-16"> Villas Coming Soon
                    </div>

                </div>
            </section>

            <Footer />
        </section>


    );
};

export default ProjectSlider;
