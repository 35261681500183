import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import doublequote from "assets/img/doublequote.png";
import testimonial from "assets/img/testimonial.png";

const SliderPage = () => {
  // const NextArrow = (props) => {
  //   const { className, onClick } = props;
  //   return (
  //     <div
  //       className={`${className} red-button`}
  //       style={{
  //         right: "0",
  //         zIndex: "1",
  //         cursor: "pointer",
  //       }}
  //       onClick={onClick}
  //     >
  //       Next
  //     </div>
  //   );
  // };

  // const PrevArrow = (props) => {
  //   const { className, onClick } = props;
  //   return (
  //     <div
  //       className={`${className} red-button`}
  //       style={{
  //         left: "0",
  //         zIndex: "1",
  //         cursor: "pointer",
  //       }}
  //       onClick={onClick}
  //     >
  //       Prev
  //     </div>
  //   );
  // };

  const settings = {
    dots: false,
    infinite: true,
    // centerPadding: "10px",
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="container xl:px-16 px-4 lg:pb-12 md:pb-4 pb-8 mx-auto overflow-hidden relative">
      <div className="bg-white text-black xl:text-4xl lg:text-4xl md:text-3xl text-xl font-semibold text-center xl:pt-4 lg:pt-12 md:pt-8 pt-6 hometext">
        Testimonials<br></br>
        <p className="xl:text-base lg:text-base md:text-base text-xs font-normal xl:pt-2 lg:pt-2 pt-2 xl:pb-8 lg:pb-4 md:pb-4 pb-4">
          Crafting Your Perfect Heaven, Beyond Imagination
        </p>
      </div>
      <Slider {...settings}>
        <div className="xl:px-2 px-2">
          <div className="bg-sky-100 rounded-md xl:p-12 lg:p-8 md:p-4 p-8">
            <div>
              <img
                src={doublequote}
                className="h-6 w-6 xl:mb-4 md:mb-2 mb-2"
              ></img>
              <p className="xl:text-base md:text-sm">
                Choosing Pruthvi Projects in Bangalore was a game-changer. Their attention to detail & professionalism exceeded expectations."
              </p>
              <div className="flex">
                <div className="rounded-full">
                  <img
                    src={testimonial}
                    className="rounded-full xl:w-16 xl:h-16 md:w-12 md:h-12 h-16 w-16 mt-4 xl:mt-6 md:mt-4"
                  ></img>
                </div>

                <div className="xl:ml-8 xl:mt-8 md:ml-4 ml-4 mt-5 md:mt-3">
                  by <b>Rajesh Patel </b>
                  <br></br>
                  <p className="xl:text-sm md:text-xs">Bangalore, India</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:px-2 px-2">
          <div className="bg-sky-100 rounded-md xl:p-12 lg:p-8 md:p-4 p-8">
            <div>
              <img
                src={doublequote}
                className="h-6 w-6 xl:mb-4 md:mb-2 mb-2"
              ></img>
              <p className="xl:text-base md:text-sm">
                Working with  Pruthvi Projects in Bangalore was a pleasure.
                Delighted with the outcomes. Wouldn't hesitate to collaborate again."
              </p>
              <div className="flex">
                <div className="rounded-full">
                  <img
                    src={testimonial}
                    className="rounded-full xl:w-16 xl:h-16 md:w-12 md:h-12 h-16 w-16 mt-4 xl:mt-6 md:mt-4"
                  ></img>
                </div>

                <div className="xl:ml-8 xl:mt-8 md:ml-4 ml-4 mt-5 md:mt-3">
                  by <b>Priya Sharma</b>
                  <br></br>
                  <p className="xl:text-sm md:text-xs">Bangalore, India</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:px-2 px-2">
          <div className="bg-sky-100 rounded-md xl:p-12 lg:p-8 md:p-4 p-8">
            <div>
              <img
                src={doublequote}
                className="h-6 w-6 xl:mb-4 md:mb-2 mb-2"
              ></img>
              <p className="xl:text-base md:text-sm">
                Impressed with the results from Pruthvi Projects in Bangalore.
                They fulfilled their promises with reliability. Strongly endorse their services.
              </p>
              <div className="flex">
                <div className="rounded-full">
                  <img
                    src={testimonial}
                    className="rounded-full xl:w-16 xl:h-16 md:w-12 md:h-12 h-16 w-16 mt-4 xl:mt-6 md:mt-4"
                  ></img>
                </div>

                <div className="xl:ml-8 xl:mt-8 md:ml-4 ml-4 mt-5 md:mt-3">
                  by <b>Vikram Rao</b>
                  <br></br>
                  <p className="xl:text-sm md:text-xs">Bangalore, India</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:px-2 px-2">
          <div className="bg-sky-100 rounded-md xl:p-12 lg:p-8 md:p-4 p-8">
            <div>
              <img
                src={doublequote}
                className="h-6 w-6 xl:mb-4 md:mb-2 mb-2"
              ></img>
              <p className="xl:text-base md:text-sm">
                Collaborating with Pruthvi Projects in Bangalore transformed our business. Consistently exceeds expectations with
                success."
              </p>
              <div className="flex">
                <div className="rounded-full">
                  <img
                    src={testimonial}
                    className="rounded-full xl:w-16 xl:h-16 md:w-12 md:h-12 h-16 w-16 mt-4 xl:mt-6 md:mt-4"
                  ></img>
                </div>

                <div className="xl:ml-8 xl:mt-8 md:ml-4 ml-4 mt-5 md:mt-3">
                  by <b>Yathin Reddy</b>
                  <br></br>
                  <p className="xl:text-sm md:text-xs">Bangalore, India</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:px-2 px-2">
          <div className="bg-sky-100 rounded-md xl:p-12 lg:p-8 md:p-4 p-8">
            <div>
              <img
                src={doublequote}
                className="h-6 w-6 xl:mb-4 md:mb-2 mb-2"
              ></img>
              <p className="xl:text-base md:text-sm">
                Working with Pruthvi Projects in Bangalore was fantastic!
                Their expertise and dedication has always delivered exceptional results."

              </p>
              <div className="flex">
                <div className="rounded-full">
                  <img
                    src={testimonial}
                    className="rounded-full xl:w-16 xl:h-16 md:w-12 md:h-12 h-16 w-16 mt-4 xl:mt-6 md:mt-4"
                  ></img>
                </div>

                <div className="xl:ml-8 xl:mt-8 md:ml-4 ml-4 mt-5 md:mt-3">
                  by <b>Neha Gupta</b>
                  <br></br>
                  <p className="xl:text-sm md:text-xs">Bangalore, India</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default SliderPage;
