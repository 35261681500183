import React, { useState, useRef } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footers/Footer";
import whatsapp from "assets/img/icons/whatsapp.png";
import SliderPage2 from "views/SliderPage2";
import project4 from "assets/img/project4.jpg";
import pool4 from "assets/img/pool4.jpg";
import SliderPage5 from "views/SliderPage5";
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


toast.configure({
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    maxWidth: '800px',
})


const ProjectPage3 = () => {
    const [showImage, setShowImage] = useState(false);

    const handleClick = () => {
        setShowImage(!showImage);
    };

    const [showImage1, setShowImage1] = useState(false);

    const handleClickk = () => {
        setShowImage1(!showImage1);
    };

    // next and previous image

    const [currentImage, setCurrentImage] = useState(0);

    const nextImage = () => {
        setCurrentImage((prevImage) => (prevImage === 4 ? 0 : prevImage + 1));
    };

    const prevImage = () => {
        setCurrentImage((prevImage) => (prevImage === 0 ? 4 : prevImage - 1));
    };


    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [project, setProject] = useState('');


    const form = useRef();
    const sendEmail = (e) => {

        emailjs.sendForm('service_re0yn5q', 'template_xdi9r9r', form.current, 'klAgwhrXKwJEHK3q7')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        toast('Thank you for contacting Pruthvi Projects. We have received your enquiry and will respond to you as soon as possible. ')
        e.preventDefault(); // 👈️ prevent page refresh

        // 👇️ clear all input values in the form
        setFullName('');
        setEmail('');
        setPhoneNumber('');
        setMessage('');
        setProject('');

    };

    return (
        <section>
            <IndexNavbar />
            <div className="xl:mt-16 lg:mt-22 md:mt-20 sm:mt-16 mt-16">
                {/* <div className=" ">
                    <div className="container mx-auto relative text-blue xl:px-16 px-4 xl:py-4 lg:py-4 sm:py-4 py-1">
                        <a href="index" className="link text-ivory-black">
                            Home {"  "} 
                        </a>
                        {"  "}― {"  "}
                        <a href="/project-homepage" className="link">
                            Project {"  "}
                        </a>
                        {"  "} ― {"  "}
                        Project Description
                    </div>
                </div> */}
            </div>

            <SliderPage5 />

            <div className="container xl:px-16 lg:px-12 md:px-12 sm:px-4 px-4 overflow-hidden mx-auto">
                <div className="relative">
                    <div className="flex flex-wrap mt-6 xl:mb-6 lg:mb-6 md:mb-6 sm:mb-8 mb-8 relative">
                        <div className="xl:w-5/12 lg:w-5/12 md:w-4/12 ">
                            <p className="hometext font-semibold xl:text-2xl lg:text-2xl md:text-xl text-base xl:mt-4 pl-0 xl:pl-0 md:pl-0 lg:pl-0 lg:mt-2 md:mt-2 mt-2">
                                Properties Detail
                            </p>
                            <p className="paratext xl:mt-2 lg:mt-4 md:mt-4 mt-4 xl:text-base lg:text-base md:text-sm text-base  pl-0 xl:pl-0 md:pl-0 lg:pl-0 xl:pr-0 lg:pr-4 md:pr-0 pr-0 text-xs xl:mb-2 lg:mb-2 md:mb-2 mb-4">
                                We have just added a lot more space into your life heres <b>PRUTHVIPROJECTS</b> intoducing With
                                Pruthvi Prime ,for people who are looking for quality & luxury life.
                            </p>
                            <p className="paratext xl:mt-2 lg:mt-4 md:mt-4 mt-4 xl:text-base lg:text-base md:text-sm text-base pl-0 xl:pl-0 md:pl-0 lg:pl-0 xl:pr-0 lg:pr-4 md:pr-0 pr-0 text-xs xl:mb-2 lg:mb-2 md:mb-2 mb-4">
                                We do not forget about our planet that has permited us to live on it. Thus our layout are
                                ECO friendly causing least burden on the Natural resourses. In short, we take responsibility
                                to create benchmarks with high customer satisfaction.Our aim is to add value to our esteemed
                                customers invesments which grows exceptionally.
                            </p>
                        </div>
                        <div className="xl:pl-12 lg:pl-12 md:pl-6">
                            <p className="hometext font-semibold xl:text-2xl lg:text-2xl md:text-xl text-base xl:mt-4 pl-0 xl:pl-0 md:pl-0 lg:pl-0 lg:mt-2 md:mt-2 mt-2">
                                WhyPruthvi Prime
                            </p>
                            <li className="font xl:text-base text-sm flex items-start py-2">
                                <span className="mr-2 ">➤</span> AMENITIES
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-2">
                                <span className="mr-2 ">➤</span> Grand entrance Arch
                            </li>

                            <li className="font xl:text-base text-sm flex items-start py-2">
                                <span className="mr-2 ">➤</span> 40ft wide road
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-2">
                                <span className="mr-2 ">➤</span> Underground sewage
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-2">
                                <span className="mr-2 ">➤</span> Tennis court
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-2">
                                <span className="mr-2 ">➤</span> 24/7 hrs security
                            </li>
                        </div>
                        <div className="xl:pl-12 lg:pl-12 md:pl-6 pt-0 xl:pt-12">
                            <li className="font xl:text-base text-sm flex items-start py-2">
                                <span className="mr-2 ">➤</span> Underground sewage
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-2">
                                <span className="mr-2 ">➤</span> Tennis court
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-2">
                                <span className="mr-2 ">➤</span> 24/7 hrs security
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-2">
                                <span className="mr-2 ">➤</span> Compound wall with gated community
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-2">
                                <span className="mr-2 ">➤</span> 24hrs water supply with over head tark
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-2">
                                <span className="mr-2 ">➤</span> Sanitary
                            </li>
                        </div>
                    </div>
                </div>
            </div>

            {/* Desktop tab and tab landscape view */}

            <div className="xl:block lg:block md:block hidden">
                <div className="container xl:px-16 lg:px-12 md:px-12 sm:px-4 px-4 overflow-hidden mx-auto">
                    <div className="relative">
                        <div className="align-items justify-center xl:mt-12 lg:mt-12 md:mt-12 mt-8 xl:mb-24 lg:mb-52 md:mb-24 sm:mb-8 mb-8 relative">
                            <img src="https://i.ibb.co/wr0p2F7/pruthvi-1.jpg" className="border shadow rounded-md xl:ml-4 lg:ml-4 md:ml-2 ml-2 xl:w-650 lg:w-560 md:w-430 w-250 xl:h-360 lg:h-340 md:h-280 h-180" />
                            <div class="rounded-md grey-box xl:mt-10 lg:mt-10 md:mt-8 mt-0 text-black xl:w-530 lg:w-400 md:w-330 w-100 xl:h-330 lg:h-450 md:h-540 h-500-px">
                                <div className="xl:mt-6 lg:mt-20 md:mt-2 mt-2 xl:ml-20 lg:ml-16 md:ml-12 ml-2 xl:pr-0 lg:pr-0 md:pr-4 pr-0">
                                    <p className="hometext font-semibold xl:text-2xl lg:text-2xl md:text-xl text-xs xl:mt-4 pl-0 xl:pl-0 md:pl-0 lg:pl-0 lg:mt-2 md:mt-2 mt-2">
                                        About Sarjapura
                                    </p>
                                    <p className="paratext xl:mt-4 lg:mt-6 md:mt-4 mt-4 xl:text-base lg:text-base md:text-sm pl-0 xl:pl-0 md:pl-0 lg:pl-0 xl:pr-0 lg:pr-4 md:pr-0 pr-0 text-xs xl:mb-2 lg:mb-2 md:mb-2 mb-4">
                                        Sarjapura being the most promising location in terms of investment one if the fastest growing
                                        towns in the southeastern part of Bangalore with good connectivity to key IT cluster areas like White
                                        field, Electronic city, Outer ring road, Marathahalli road.
                                    </p>

                                    <p className="paratext xl:mt-2 lg:mt-2 md:mt-4 mt-4 xl:text-base lg:text-base md:text-sm pl-0 xl:pl-0 md:pl-0 lg:pl-0 xl:pr-0 lg:pr-4 md:pr-0 pr-0 text-xs xl:mb-8 lg:mb-2 md:mb-2 mb-4">
                                        As a micro market Sarjapura is booming
                                        rapidly development in terms of residential space and Commercial place and many other presents
                                        various types of projects like villas, apartments, plotted development.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile view */}
            <div className="xl:hidden lg:hidden md:hidden block">
                <div className="container xl:px-16 lg:px-12 md:px-12 sm:px-2 px-4 overflow-hidden mx-auto">
                    <div>
                        <p className="hometext font-semibold xl:text-2xl lg:text-2xl md:text-2xl text-xl xl:mt-4 lg:mt-4 md:mt-4 mt-4">
                            About Sarjapura
                        </p>
                        <p className="paratext xl:mt-4 lg:mt-6 md:mt-4 mt-2 xl:text-lg text-base xl:mb-8 lg:mb-6 md:mb-4 mb-0">
                            Sarjapura being the most promising location in terms of investment one if the fastest growing
                            towns in the southeastern part of Bangalore with good connectivity to key IT cluster areas like White
                            field, Electronic city, Outer ring road, Marathahalli road.
                        </p>
                        <p className="paratext xl:mt-4 lg:mt-6 md:mt-4 mt-2 xl:text-lg text-base xl:mb-8 lg:mb-6 md:mb-4 mb-0">
                            Sarjapura being the most promising location in terms of investment one if the fastest growing
                            towns in the southeastern part of Bangalore with good connectivity to key IT cluster areas like White
                            field, Electronic city, Outer ring road, Marathahalli road.
                        </p>
                    </div>
                    <div><img src="https://i.ibb.co/wr0p2F7/pruthvi-1.jpg" className="border shadow rounded-md mt-4" /></div>
                </div>

            </div>

            {/* desktop view */}

            <div className="xl:block lg:block md:block hidden">
                <div className="bg-grey-100 mt-4 xl:mt-0 lg:mt-0 md:mt-12 pb-12">
                    <div className="container xl:px-16 lg:px-12 md:px-12 px-4 overflow-hidden mx-auto ">
                        <div className="grid grid-cols-3 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-2 gap-2 xl:mt-16 lg:mt-12 md:mt-12 mt-4">

                            <div className="hometext justify-left uppercase font-bold xl:text-3xl lg:text-xl md:text-xl text-sm
                        xl:ml-4 lg:ml-4 md:ml-4 ml-0">
                                OUR STRATEGIC APPROACH
                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-xl text-sm">
                                <b className="uppercase text-lg">About The Project</b>
                                <ul className="list-none xl:pt-1 pt-1">
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Investing in our project could generate huge
                                        profits in the future, as it has become the most preferred residential location
                                        for the inhabitants of Bangalore.
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> The Vision for a quality development curved in 7.5
                                        acres of land, 171 plots villa plots and a lush
                                        tropical garden setting with tranquil living space in one iconic location.
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> The perfect location to built your dream home,
                                        where you can unwind after a longs days work.
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Adjacent to 100 feet road.
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> For we have spared no effort giving you every
                                        luxury, every convenience and every comfort
                                    </li>
                                </ul>
                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-xl text-sm">
                                <b className="uppercase text-lg">Key Development</b>
                                <ul className="list-none xl:pt-1 pt-1">
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Adjacent to 100 Feet
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Infosys signed Rs.2950 crore to set up software
                                        development centre in Sarjapura.
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Wipro signed 537 crore MOU and construction going
                                        on with full swing.
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Immediate Construction.
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Ready for registration
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Complete developed location
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Walk able distance for all our basic needs
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> 10 Kilometer from National High way
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* mobile view */}

            <div className="xl:hidden lg:hidden md:hidden block">
                <div className="bg-grey-100 mt-4 pb-6 mb-6">
                    <div className="container xl:px-16 lg:px-12 md:px-12 px-4 overflow-hidden mx-auto ">
                        <div className="grid grid-rows-3 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-2 gap-2 xl:mt-16 lg:mt-14 md:mt-12 mt-4">

                            <div className="hometext justify-left uppercase font-bold xl:text-xl lg:text-xl md:text-xl text-xl xl:ml-4 lg:ml-4 md:ml-4 ml-0">
                                OUR STRATEGIC APPROACH
                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-2xl text-lg mt-4">
                                <b className="uppercase text-lg">About The Project</b>
                                <ul className="list-none xl:pt-1 pt-1">
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Investing in our project could generate huge
                                        profits in the future, as it has become the most preferred residential location
                                        for the inhabitants of Bangalore.
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> The Vision for a quality development curved in 7.5
                                        acres of land, 171 plots villa plots and a lush
                                        tropical garden setting with tranquil living space in one iconic location.
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> The perfect location to built your dream home,
                                        where you can unwind after a longs days work.
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Adjacent to 100 feet road.
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> For we have spared no effort giving you every
                                        luxury, every convenience and every comfort
                                    </li>
                                </ul>
                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-2xl text-lg mt-4">
                                <b className="uppercase text-lg">Key Development</b>
                                <ul className="list-none xl:pt-1 pt-1">
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Adjacent to 100 Feet
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Infosys signed Rs.2950 crore to set up software
                                        development centre in Sarjapura.
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Wipro signed 537 crore MOU and construction going
                                        on with full swing.
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Immediate Construction.
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Ready for registration
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Complete developed location
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> Walk able distance for all our basic needs
                                    </li>
                                    <li className="font xl:text-base text-sm flex items-start py-2">
                                        <span className="mr-2 ">➤</span> 10 Kilometer from National High way
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            {/* Desktop tab and tab landscape view */}

            <div className="xl:block lg:block md:block hidden">
                <div className="container xl:px-16 lg:px-12 md:px-12 sm:px-4 px-4 overflow-hidden mx-auto">
                    <div className="relative">
                        <div className="align-items justify-center xl:mt-12 lg:mt-12 md:mt-12 mt-8 xl:mb-16 lg:mb-24 md:mb-24 sm:mb-8 mb-8 relative">
                            <img src="https://i.ibb.co/LYMbcB1/pruthvi-2.jpg" className="rounded-md xl:ml-4 lg:ml-4 md:ml-2 ml-2 xl:w-650 lg:w-560 md:w-430 w-250 xl:h-360 lg:h-340 md:h-280 h-180" />
                            <div class="rounded-md grey-box xl:mt-10 lg:mt-10 md:mt-8 mt-0 text-black xl:w-530 lg:w-380 md:w-330 w-100 xl:h-355 lg:h-340 md:h-280 h-150">
                                <div className="xl:mt-16 lg:mt-20 md:mt-16 mt-2 xl:ml-20 lg:ml-16 md:ml-12 ml-2 xl:pr-0 lg:pr-0 md:pr-4 pr-0">
                                    <p className="hometext font-semibold xl:text-2xl lg:text-2xl md:text-xl text-base xl:mt-4 lg:mt-2 md:mt-2 mt-4">
                                        Project Specification</p>
                                    <p className="paratext xl:mt-4 lg:mt-6 md:mt-4 mt-4 xl:text-lg lg:text-base md:text-sm xl:mb-8 lg:mb-6 md:mb-4 mb-4">
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Approval -DC converted
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Land area - 7.5 acres of land
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Type- 171 Villa Plots
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Dimension: 30*40, Odd dimension
                                        </li>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile view */}


            <div className="xl:hidden lg:hidden md:hidden block">
                <div className="container xl:px-16 lg:px-12 md:px-12 sm:px-2 px-4 overflow-hidden mx-auto">
                    <div><img src="https://i.ibb.co/LYMbcB1/pruthvi-2.jpg" className="border shadow rounded-md mt-2" /></div>
                    <div>
                        <p className="hometext font-semibold xl:text-2xl lg:text-2xl md:text-2xl text-xl xl:mt-4 lg:mt-2 md:mt-2 mt-4">
                            Project Specification</p>
                        <p className="paratext xl:mt-4 lg:mt-6 md:mt-4 mt-2 xl:text-lg xl:mb-8 lg:mb-6 md:mb-4 mb-4">
                            <li className="font xl:text-base text-sm flex items-start py-2">
                                <span className="mr-2 ">➤</span> Approval -DC converted
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-1 ">
                                <span className="mr-2">➤</span>  Land area - 7.5 acres of land
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-1 ">
                                <span className="mr-2">➤</span>  Type- 171 Villa Plots
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-1 ">
                                <span className="mr-2">➤</span>  Dimension: 30*40, Odd dimension
                            </li>
                        </p>
                    </div>
                </div>
            </div>




            <div className="xl:block lg:block md:block hidden">
                <div className="bg-grey-100 mt-4 xl:mt-0 lg:mt-0 md:mt-12">
                    <div className="container xl:px-16 lg:px-12 md:px-12 px-4 overflow-hidden mx-auto ">
                        <div className="grid grid-cols-3 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-2 gap-2 xl:mt-16 lg:mt-12 md:mt-12 mt-4">

                            <div className="hometext justify-left uppercase font-bold xl:text-3xl lg:text-xl md:text-xl text-sm
                        xl:ml-4 lg:ml-4 md:ml-4 ml-0">
                                OUR STRATEGIC APPROACH
                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-xl text-sm">
                                <b className="uppercase text-lg">Schools Nearby</b>
                                <p className="font">
                                    <ul className="list-none xl:pt-2 pt-2">
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Indus International School
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-1 ">
                                            <span className="mr-2">➤</span>  Gear Innovative International School
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2 ">
                                            <span className="mr-2">➤</span>  Green wood High School
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2 ">
                                            <span className="mr-2">➤</span>  Inventure Academy
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2 ">
                                            <span className="mr-2">➤</span>  Amaatra Academy
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2 ">
                                            <span className="mr-2">➤</span>  Harvest International
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2 ">
                                            <span className="mr-2">➤</span>  Oakridge International School
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2 ">
                                            <span className="mr-2">➤</span>  Global Indian International School
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2 ">
                                            <span className="mr-2">➤</span>  Royale Concorde International School
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2 ">
                                            <span className="mr-2">➤</span>  Sri Sri Ravi Shankar Vidaya Mandir
                                        </li>
                                    </ul>
                                </p>

                                <div className="pt-4">
                                    <b className="uppercase text-lg">Company Nearby</b>
                                    <p className="font ">
                                        <ul className="list-none xl:pt-2 pt-2">
                                            <li className="font xl:text-base text-sm flex items-start py-1">
                                                <span className="mr-2 ">➤</span> Wipro
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2">➤</span> Microsoft India
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2 ">➤</span> Eco space
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2 ">➤</span> Intel Technology
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2 ">➤</span> Embassy Tech village
                                            </li>
                                        </ul>
                                    </p>
                                </div>

                                <div className="pt-4">
                                    <b className="uppercase text-lg">Transportation Nearby</b>
                                    <p className="font ">
                                        <ul className="list-none xl:pt-2 pt-2">
                                            <li className="font xl:text-base text-sm flex items-start py-1">
                                                <span className="mr-2 ">➤</span> Bus available from Majestic
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2">➤</span> Market, Shivijinagar, Hebbal, Devenahalli airport.
                                            </li>

                                        </ul>
                                    </p>
                                </div>

                                <div className="pt-4">
                                    <b className="uppercase text-lg">Developed Area Nearby</b>
                                    <p className="font ">
                                        <ul className="list-none xl:pt-2 pt-2">
                                            <li className="font xl:text-base text-sm flex items-start py-1">
                                                <span className="mr-2 ">➤</span> White field
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2">➤</span> Electronic city
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2">➤</span> Outer Ring Road
                                            </li>
                                        </ul>
                                    </p>
                                </div>

                                <div className="pt-4">
                                    <b className="uppercase text-lg">Approved By Major Banks</b>
                                    <p className="font ">
                                        <ul className="list-none xl:pt-2 pt-2">
                                            <li className="font xl:text-base text-sm flex items-start py-1">
                                                <span className="mr-2 ">➤</span> LIC Housing finance
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2">➤</span> HDFC housing finance
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2">➤</span> Yesbank
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-xl text-sm ">
                                <b className="uppercase text-lg">AMENITIES</b>
                                <p className="font ">
                                    <ul className="list-none xl:pt-2 pt-2">
                                        <li className="font xl:text-base text-sm flex items-start py-1">
                                            <span className="mr-2 ">➤</span> Gated Community
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span> Sewage Treatment plant
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Electricity
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> 24/7 Security
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Rain water harvesting
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Complete compound wall
                                        </li>
                                    </ul>
                                </p>

                                <div className="pt-4">
                                    <b className="uppercase text-lg">Hospital Nearby</b>
                                    <p className="font ">
                                        <ul className="list-none xl:pt-2 pt-2">
                                            <li className="font xl:text-base text-sm flex items-start py-1">
                                                <span className="mr-2 ">➤</span> Narayana Multispeciality clinic
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2">➤</span> Columbia Asia Hospital
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2 ">➤</span> Town Hospital Sarjapura
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2 ">➤</span> Motherhood Hospital
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2 ">➤</span> Kshema Hospital
                                            </li>
                                        </ul>
                                    </p>
                                </div>

                                <div className="pt-4">
                                    <b className="uppercase text-lg">Shopping Mall Nearby</b>
                                    <p className="font ">
                                        <ul className="list-none xl:pt-2 pt-2">
                                            <li className="font xl:text-base text-sm flex items-start py-1">
                                                <span className="mr-2 ">➤</span> Metro
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2">➤</span> D-Mart
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2 ">➤</span> NVKM complex
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2 ">➤</span> N.R.Complex
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2 ">➤</span> A one super Market
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2 ">➤</span> Sai Enterprises
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2 ">➤</span> Pai Show room
                                            </li>
                                        </ul>
                                    </p>
                                </div>

                                <div className="pt-4 pb-16">
                                    <b className="uppercase text-lg">Project AMENITIES</b>
                                    <p className="font ">
                                        <ul className="list-none xl:pt-2 pt-2">
                                            <li className="font xl:text-base text-sm flex items-start py-1">
                                                <span className="mr-2 ">➤</span> Gated Community
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2">➤</span> Sewage Treatment plant
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2 ">➤</span> Electricity
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2 ">➤</span> 24/7 Security
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2 ">➤</span> Rain water harvesting
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2 ">➤</span> Over head water tank
                                            </li>
                                            <li className="font xl:text-base text-sm flex items-start py-2">
                                                <span className="mr-2 ">➤</span> Complete compound wall
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            {/* mobile view */}

            <div className="xl:hidden lg:hidden md:hidden block">
                <div className="bg-grey-100 mt-4">
                    <div className="container xl:px-16 lg:px-12 md:px-12 px-4 overflow-hidden mx-auto ">
                        <div className="grid grid-rows-3 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-2 gap-2 xl:mt-16 lg:mt-14 md:mt-12 mt-4">

                            <div className="hometext justify-left uppercase font-bold xl:text-xl lg:text-xl md:text-xl text-xl xl:ml-4 lg:ml-4 md:ml-4 ml-0">
                                OUR STRATEGIC APPROACH
                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-2xl text-lg mt-4">
                                <b className="uppercase">Schools Nearby</b>
                                <p className="font py-2">
                                    <ul className="list-none xl:pt-2 pt-2">
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Indus International School
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span>  Gear Innovative International School
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span>  Green wood High School
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span>  Inventure Academy
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span>  Amaatra Academy
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span>  Harvest International
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span>  Oakridge International School
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span>  Global Indian International School
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span>  Royale Concorde International School
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span>  Sri Sri Ravi Shankar Vidaya Mandir
                                        </li>
                                    </ul>
                                </p>


                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-2xl text-lg mt-4">
                                <b className="uppercase">Project AMENITIES</b>
                                <p className="font py-2">
                                    <ul className="list-none xl:pt-2 pt-2">
                                        <li className="font xl:text-base text-sm flex items-start ">
                                            <span className="mr-2 ">➤</span> Gated Community
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span> Sewage Treatment plant
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Electricity
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> 24/7 Security
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Rain water harvesting
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Over head water tank
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Complete compound wall
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-2xl text-lg mt-4">
                                <b className="uppercase">Company Nearby</b>
                                <p className="font py-2">
                                    <ul className="list-none xl:pt-2 pt-2">
                                        <li className="font xl:text-base text-sm flex items-start ">
                                            <span className="mr-2 ">➤</span> Wipro
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span> Microsoft India
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Eco space
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Intel Technology
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Embassy Tech village
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-2xl text-lg mt-4">
                                <b className="uppercase"> Approved By Major Banks</b>
                                <p className="font py-2">
                                    <ul className="list-none xl:pt-2 pt-2">
                                        <li className="font xl:text-base text-sm flex items-start ">
                                            <span className="mr-2 ">➤</span> LIC Housing finance
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span> HDFC housing finance
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Yesbank
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-2xl text-lg mt-4">
                                <b className="uppercase"> Shopping Mall</b>
                                <p className="font py-2">
                                    <ul className="list-none xl:pt-2 pt-2">
                                        <li className="font xl:text-base text-sm flex items-start ">
                                            <span className="mr-2 ">➤</span> Metro
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span> D-Mart
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> NVKM complex
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> N.R.Complex
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> A one super Market
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Sai Enterprises
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Pai Show room
                                        </li>
                                    </ul>
                                </p>
                            </div>
                            <div className="hometext xl:text-2xl lg:text-2xl md:text-2xl text-lg mt-4">
                                <b className="uppercase"> Hospital Nearby</b>
                                <p className="font py-2">
                                    <ul className="list-none xl:pt-2 pt-2">
                                        <li className="font xl:text-base text-sm flex items-start ">
                                            <span className="mr-2 ">➤</span> Narayana Multispeciality clinic
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span> Columbia Asia Hospital
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Town Hospital Sarjapura
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Motherhood Hospital
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2 ">➤</span> Kshema Hospital
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-2xl text-lg mt-4">
                                <b className="uppercase">Transportation Facility</b>
                                <p className="font py-2">
                                    <ul className="list-none xl:pt-2 pt-2">
                                        <li className="font xl:text-base text-sm flex items-start ">
                                            <span className="mr-2 ">➤</span> Bus available from Majestic
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span> Market, Shivijinagar, Hebbal, Devenahalli airport.
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-2xl text-lg mt-4">
                                <b className="uppercase">Developed Area Nearby</b>
                                <p className="font py-2">
                                    <ul className="list-none xl:pt-2 pt-2">
                                        <li className="font xl:text-base text-sm flex items-start py-1">
                                            <span className="mr-2 ">➤</span> White field
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span> Electronic city
                                        </li>
                                        <li className="font xl:text-base text-sm flex items-start py-2">
                                            <span className="mr-2">➤</span> Outer Ring Road
                                        </li>
                                    </ul>
                                </p>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            {/* desktop view  */}

            <div className="xl:block lg:block md:block hidden">
                <div className="bg-grey-50 xl:pb-0 xl:mt-0 lg:mt-0 md:mt-12 lg:pb-12 md:pb-16">
                    <div className="container xl:px-16 lg:px-12 md:px-12 px-4 overflow-hidden mx-auto">
                        <div className="relative grid grid-cols-2">

                            <div className="hometext xl:text-4xl lg:text-3xl md:text-3xl text-base xl:mt-8 lg:mt-8 md:mt-8 mt-6 md:mb-12 ">
                                <div>
                                    <div className="xl:mt-8 lg:mt-4 md:mt-4 mt-4 xl:mb-12 xl:gap-4 lg:gap-4 md:gap-4 lg:grid-cols-2 md:grid-cols-2 grid-cols-1">
                                        <form ref={form} onSubmit={sendEmail} className="bg-white rounded-md text-black xl:p-5 lg:p-5 md:p-4">
                                            <div className="xl:text-2xl lg:text-xl md:text-lg font font-semibold">
                                                Real Estate Enquiry Form <br></br>
                                                <p className="xl:text-lg lg:text-sm md:text-sm text-sm font-normal font xl:mt-2">
                                                Contact us today if you’d like to know more about how we help buy, or rent your home
                                                </p>
                                            </div>

                                            <div className="grid grid-rows-4 xl:gap-4 md:gap-2 sxl:mt-4 md:mt-4 lg:gap-2 lg:mt-2">
                                                <input
                                                    type="text"
                                                    className="pl-4 rounded font border-light-grey h-10 md:text-xs xl:text-base lg:text-base text-xs"
                                                    placeholder="Enter Name"
                                                    name="fullName"
                                                    maxlength="35" onChange={event => setFullName(event.target.value)}
                                                    value={fullName} required
                                                />
                                                <input
                                                    className="pl-4 rounded font border-light-grey h-10 md:text-xs xl:text-base lg:text-base text-xs"
                                                    type="tel"
                                                    placeholder="Enter Phone Number"
                                                    name="phoneNumber"
                                                    value={phoneNumber}
                                                    onChange={event => setPhoneNumber(event.target.value)} required
                                                ></input>
                                                <input
                                                    className="pl-4 rounded font border-light-grey h-10 md:text-xs xl:text-base lg:text-base text-xs"
                                                    name="email"
                                                    type="email"
                                                    placeholder="Enter Email"
                                                    value={email}
                                                    onChange={event => setEmail(event.target.value)} required
                                                ></input>
                                            </div>

                                            <div className="grid lg:grid-rows-1 mt-2 lg:mt-2">
                                                <select
                                                    id="EnquiryType"
                                                    className="xl:mt-2 font rounded-md border-light-grey w-full h-10 px-4"
                                                    value={project} name="project"
                                                    onChange={event => setProject(event.target.value)} required // Make project selection required
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Pruthvi Home">Pruthvi Home</option>
                                                    <option value="Pruthvi Home">Pruthvi Asset</option>
                                                    <option value="Pruthvi Paradise">Pruthvi Paradise</option>
                                                    <option value="Pruthvi Prime">Pruthvi Prime</option>
                                                </select>
                                            </div>

                                            <div className="grid grid-rows-1 xl:gap-4 mt-4">
                                                <textarea
                                                    className="pl-4 rounded font border-light-grey h-20 md:text-xs xl:text-base lg:text-base text-xs"
                                                    placeholder="Hello, I am interested in [Design apartment]"
                                                    value={message}
                                                    name="message"
                                                    onChange={event => setMessage(event.target.value)} required></textarea>

                                            </div>

                                            {/* <div className="xl:mt-4">
                        <input
                          type="checkbox"
                          className="xl:ml-2 form-checkbox h-3 w-3 text-blue-950 "
                        />
                        <span className="xl:ml-4 text-blue-950 font">
                          By submitting this form I agree to Terms of Use
                        </span>
                      </div> */}

                                            <div className="grid grid-cols-1 xl:gap-4 lg:gap-2 md:gap-2 xl:mt-8 md:mt-2 lg:mt-2">
                                                <button className="font gradient2 rounded border text-white lg:text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2"
                                                    type="submit"
                                                    value="submit">
                                                    Send Message
                                                </button>
                                            </div>

                                            <div className="grid grid-cols-2 gap-2 items-center xl:mt-2 lg:mt-2 md:mt-1 mt-2">
                                                <a href="https://wa.me/+919900533422" className="pt-1">
                                                    <button className="w-12/12 rounded gradient2 border text-white lg:text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2">
                                                        <div className="flex justify-center items-center">
                                                            <img src={whatsapp} className="w-4 h-4  mr-2" />
                                                            Whats App
                                                        </div>
                                                    </button>
                                                </a>
                                                <a href="tel:+919900533422" className="-mt-1">
                                                    <button className="w-12/12 font gradient2 rounded border text-white  lg:text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2">
                                                        Call
                                                    </button>
                                                </a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                            <div className="relative grid grid-cols-1">

                                <div className="xl:mt-8 lg:mt-4 md:mt-48 mt-4 xl:ml-8 lg:ml-4 md:ml-4 ml-2 ">
                                    <img src="https://i.ibb.co/xH82tP1/Layout-image.jpg" className="rounded-md md:mt-18 xl:h-510 lg:h-450 md:h-475"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* mobile view  */}

            <div className="xl:hidden lg:hidden md:hidden block">
                <div className="bg-grey-100">
                    <div className="container xl:px-16 lg:px-12 md:px-12 px-4 overflow-hidden mx-auto">
                        <div className="relative grid grid-rows-2">

                            <div className="hometext xl:text-4xl lg:text-3xl md:text-2xl text-2xl
                        xl:mt-8 lg:mt-8 md:mt-8 mt-6">


                                <div>
                                    <div className="xl:mt-8 lg:mt-4 md:mt-4 mt-4 xl:mb-12 xl:gap-4 lg:gap-4 md:gap-4 lg:grid-cols-2 md:grid-cols-2 grid-cols-1">
                                        <div className="bg-white rounded-md text-black xl:p-5 lg:p-5 p-3">
                                            <form ref={form} onSubmit={sendEmail} className=" ">
                                                <div className="xl:text-2xl lg:text-xl md:text-lg font font-semibold">
                                                    Real Estate Enquiry Form <br></br>
                                                    <p className="xl:text-lg lg:text-sm md:text-sm text-sm font-normal font xl:mt-2">
                                                    Contact us today if you’d like to know more about how we help buy, or rent your home
                                                    </p>
                                                </div>

                                                <div className="grid grid-rows-4 xl:gap-4 md:gap-2 sxl:mt-4 md:mt-4 lg:gap-2 lg:mt-2">
                                                    <input
                                                        type="text"
                                                        className="pl-4 rounded font border-light-grey h-10 md:text-xs my-2 xl:text-base lg:text-base text-xs"
                                                        placeholder="Enter Name"
                                                        name="fullName"
                                                        maxlength="35" onChange={event => setFullName(event.target.value)}
                                                        value={fullName} required
                                                    />
                                                    <input
                                                        className="pl-4 rounded font border-light-grey h-10 md:text-xs my-2 xl:text-base lg:text-base text-xs"
                                                        type="tel"
                                                        placeholder="Enter Phone Number"
                                                        name="phoneNumber"
                                                        value={phoneNumber}
                                                        onChange={event => setPhoneNumber(event.target.value)} required
                                                    ></input>
                                                    <input
                                                        className="pl-4 rounded font border-light-grey h-10 md:text-xs my-2 xl:text-base lg:text-base text-xs"
                                                        name="email"
                                                        type="email"
                                                        placeholder="Enter Email"
                                                        value={email}
                                                        onChange={event => setEmail(event.target.value)} required
                                                    ></input>
                                                </div>

                                                <div className="grid lg:grid-rows-1 mt-2 lg:mt-2">
                                                    <select
                                                        id="EnquiryType"
                                                        className="xl:mt-2 font rounded-md border-light-grey w-full h-10 px-4"
                                                        value={project} name="project"
                                                        onChange={event => setProject(event.target.value)} required // Make project selection required
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Pruthvi Home">Pruthvi Home</option>
                                                        <option value="Pruthvi Home">Pruthvi Asset</option>
                                                        <option value="Pruthvi Paradise">Pruthvi Paradise</option>
                                                        <option value="Pruthvi Prime">Pruthvi Prime</option>
                                                    </select>
                                                </div>

                                                <div className="grid grid-rows-1 xl:gap-4 mt-4">
                                                    <textarea
                                                        className="pl-4 rounded font border-light-grey h-20 md:text-xs xl:text-base lg:text-base text-xs"
                                                        placeholder="Hello, I am interested in [Design apartment]"
                                                        value={message}
                                                        name="message"
                                                        onChange={event => setMessage(event.target.value)} required></textarea>

                                                </div>

                                                {/* <div className="xl:mt-4">
                        <input
                          type="checkbox"
                          className="xl:ml-2 form-checkbox h-3 w-3 text-blue-950 "
                        />
                        <span className="xl:ml-4 text-blue-950 font">
                          By submitting this form I agree to Terms of Use
                        </span>
                      </div> */}

                                                <div className="grid grid-cols-1 xl:gap-4 lg:gap-2 md:gap-2 mt-8 md:mt-2 lg:mt-2">
                                                    <button className="font gradient2 rounded border text-white text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2"
                                                        type="submit"
                                                        value="submit">
                                                        Send Message
                                                    </button>
                                                </div>
                                            </form>

                                            <div className="grid grid-cols-2 gap-2 items-center xl:mt-4 lg:mt-2 md:mt-1 mt-2">
                                                <a href="https://wa.me/+919900533422" className="pt-1">
                                                    <button className="w-12/12 rounded gradient2 border text-white text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2">
                                                        <div className="flex justify-center items-center">
                                                            <img src={whatsapp} className="w-4 h-4  mr-2" />
                                                            Whats App
                                                        </div>
                                                    </button>
                                                </a>
                                                <a href="tel:+919900533422" className="-mt-1">
                                                    <button className="w-12/12 font gradient2 rounded border text-white  text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2">
                                                        Call
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="relative grid grid-cols-1">

                                <div className="xl:mt-26 lg:mt-6 md:mt-6 mt-4 xl:ml-8 lg:ml-4 md:ml-4 ml-0 mb-4">
                                    <img src={project4} className="rounded-md"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section >
    );
};

export default ProjectPage3;
