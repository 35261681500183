import React from 'react';

const LoadingSpinner = () => {
    return (
        <div className=" preloader-container ">
            <div className="preloader xl:my-96 z-50 justify-center content-center center">
                <img src='https://i.ibb.co/dg5Hyv1/logo.png' className='w-56 fadeinout' />
            </div>
        </div>
    );
};

export default LoadingSpinner;
