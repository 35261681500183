import React from 'react'
import { render } from 'react-dom';
import ReactWhatsapp from 'react-whatsapp';

export default function WhatsApp() {

    return (
        <div className=''>
            <div class="floating_btn">
                <a target="_blank" href="https://wa.me/+919900533422">
                    <div class="contact_icon">
                        <img src='https://i.ibb.co/ynNnRrq/whatsapp.png' className='' alt=''/>
                    </div>
                </a>
                {/* <p class="text_icon ">Talk to us?</p> */}
            </div>
        </div>
    )
}