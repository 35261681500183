import React, { useState, useEffect, useRef } from "react";
import Footer from "components/Footers/Footer.js";
import SliderPage from "views/SliderPage";
import bgpruthviii from "assets/img/bgpruthviii.jpg";
import mapmarker from "assets/img/mapmarker.png";
import pruthvibanner from "assets/img/pruthvibanner.png";
import whatsapp from "assets/img/icons/whatsapp.png";
import pruthvibannermobile from "assets/img/images/pruthvibannermobile.jpg";
import asset1 from "assets/img/asset1.jpg";
import paradise2 from "assets/img/paradise2.jpg";
import asset2 from "assets/img/asset2.jpg";
import project4 from "assets/img/project4.jpg";
import construction from "assets/img/construction.jpg";
import site2 from "assets/img/site2.avif";
import villa1 from "assets/img/villa1.jpeg";
import phoneicon from "assets/img/phoneicon.png";
import call1 from "assets/img/icons/call1.png";
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HashLink } from "react-router-hash-link";
import projectvivanta from "assets/img/projectvivanta.jpeg";
import vivanta from "assets/img/vivanta.jpeg"


toast.configure({
  position: toast.POSITION.TOP_CENTER,
  autoClose: 3000,
  maxWidth: '800px',
})

export default function Homepage1() {

  const [hoveredItem, setHoveredItem] = useState(null);

  // Function to handle automatic sliding
  useEffect(() => {
    const interval = setInterval(() => {
      setHoveredItem(prevHoveredItem => (prevHoveredItem === 1 ? 2 : prevHoveredItem === 2 ? 3 : 1));
    }, 5000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, []);



  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [project, setProject] = useState('');


  const form = useRef();
  const sendEmail = (e) => {

    emailjs.sendForm('service_wg4e5c8', 'template_b1psnmg', form.current, '3rIFlKZWzk1hJ7FUF')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });

    toast('Thank you for contacting Pruthvi Projects. We have received your enquiry and will respond to you as soon as possible. ')
    e.preventDefault(); // 👈️ prevent page refresh

    // 👇️ clear all input values in the form
    setFullName('');
    setEmail('');
    setPhoneNumber('');
    setMessage('');
    setProject('');

  };





  const handleButtonClickCall = () => {
    window.location.href = "tel:+919900533422";
  };
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    openPopup();

    return () => {
      closePopup();
    };
  }, []);


  const settings = {
    dots: false,
    infinite: true,
    // centerPadding: "10px",
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: true,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };



  const settings1 = {
    dots: false,
    infinite: true,
    // centerPadding: "10px",
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 5,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },

    ],
  };

  return (
    <section>


      <Slider {...settings}>
        <div className="">
          <div className="xl:pt-0 lg:pt-0 md:pt-16  xl:pb-0  xl:block lg:block md:block block">


            <div className="container mx-auto xl:px-12">
              <div className="flex flex-wrap items-center xl:pt-12 lg:pt-12 md:pt-2 xl:pl-12 pl-4">
                <div className="w-12/12 justify-center items-center text-center pt-12 xl:hidden lg:hidden md:hidden block sm:block" style={{ textAlign: "-webkit-center" }}>
                  <img src="https://i.ibb.co/fqh4wFQ/mobile01.png" className="justify-center items-center text-center w-auto"></img>
                </div>
                <div className="xl:w-6/12 lg:w-6/12 md:w-5/12 w-12/12 lg:pt-0 xl:pt-12 xl:-mt-0 -mt-10">
                  <div className="text-left z-50 ">
                    <h2 className="hometext uppercase font-boldd xl:text-4xl lg:text-2xl text-base text-ivory-black xl:mt-16 lg:mt-28 lg:ml-2 mt-12">
                      Welcome To Pruthvi
                    </h2>
                    <div className="xl:block lg:block md:block block">
                      <p className=" paratext xl:text-base lg:text-base text-sm text-ivory-black leading-relaxed xl:pt-4 lg:ml-2 lg:pt-4 pt-2">
                        Established in 2011, Pruthvi Projects focuses on constructing superior apartments, offering quality services at fair prices. We prioritize customer satisfaction by providing cost-effective designs tailored to their needs and addressing concerns promptly.
                      </p>
                    </div>
                    <div className="xl:pt-4 lg:pt-4 pt-2">
                      <HashLink smooth to="/#enquiryy">
                        <button className="text-white gradient2 xl:text-sm text-xs xl:px-12 lg:ml-2 px-8 py-2 mt-2 rounded font-semibold uppercase xl:py-2  bg-lime-500">
                          Get Info
                        </button>
                      </HashLink>
                      <a href="/project-homepage">
                        <button className="text-white gradient2 xl:text-sm text-xs xl:px-12 lg:ml-2 md:ml-2 ml-2 px-8 py-2 mt-2 rounded font-semibold uppercase xl:ml-4 xl:py-2 bg-lime-500">
                          Projects
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-12/12 xl:w-6/12 lg:w-6/12 md:w-6/12 xl:pl-24 justify-center items-center text-center xl:pt-18 lg:pt-24 pt-12 xl:block lg:block md:block hidden sm:hidden" style={{ textAlign: "-webkit-center" }}>
                  <img src="https://i.ibb.co/dg5Hyv1/logo.png" className="xl:h-360 h-240  justify-center items-center text-center w-auto"></img>
                </div>

              </div>
            </div>
          </div>
        </div>


        <div className="">
          <div className="xl:pt-0 lg:pt-0 md:pt-16  xl:pb-0  xl:block lg:block md:block block">
            <div className="container mx-auto xl:px-12">
              <div className="flex flex-wrap items-center xl:pt-12 lg:pt-0 md:pt-2 pt-2 xl:pl-12 lg:pl-0 md:pl-0 pl-4">
                <div className="w-12/12 justify-center items-center pr-4 text-center pt-12 xl:hidden lg:hidden md:hidden block sm:block" style={{ textAlign: "-webkit-center" }}>
                  <img src="https://i.ibb.co/FqQXvQB/mobile03.png" className="justify-center items-center text-center w-auto"></img>
                </div>
                <div className="xl:w-6/12 lg:w-6/12 md:w-5/12 ">
                  <div className="text-left z-50 ">
                    <h2 className="hometext uppercase font-boldd xl:text-4xl lg:text-2xl text-base text-ivory-black xl:mt-16 lg:mt-28 lg:ml-2 mt-6">
                      Pruthvi Homes
                    </h2>
                    <div className="xl:block lg:block md:block block">
                      <p className=" paratext xl:text-base lg:text-base text-sm text-ivory-black leading-relaxed xl:pt-4 lg:ml-2 lg:pt-4">
                        As Pruthvi, we follow best practices that include concrete tests for strength assessment and the use of suitable vibrators for leveling and creating a smooth surface.
                      </p>
                    </div>
                    <div className="xl:pt-4 lg:pt-4">
                      <HashLink smooth to="/#enquiryy">
                        <button className="text-white gradient2 xl:text-sm text-xs xl:px-12 lg:ml-2 px-8 py-2 mt-2 rounded font-semibold uppercase xl:py-2  bg-lime-500">
                          Get Info
                        </button>
                      </HashLink>
                      <a href="/project-homepage">
                        <button className="text-white gradient2 xl:text-sm text-xs xl:px-12 lg:ml-2 md:ml-2 ml-2 px-8 py-2 mt-2 rounded font-semibold uppercase xl:ml-4 xl:py-2 bg-lime-500">
                          Projects
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="xl:pl-24  xl:w-6/12 lg:w-6/12 md:w-6/12 justify-center items-center text-center xl:pt-4 lg:pt-24 pl-8 pt-12 xl:block lg:block md:block hidden sm:hidden">
                  <img src="https://i.ibb.co/qrqxHDD/pruthvihomes-min.png" className="xl:h-450 h-280  justify-center items-center text-center"></img>
                </div> */}
                <div className="xl:pl-24   xl:w-6/12 lg:w-6/12 md:w-6/12 justify-center items-center text-center xl:pt-4 lg:pt-24 xl:block lg:block md:block hidden sm:hidden">
                  <img src="https://i.ibb.co/qrqxHDD/pruthvihomes-min.png" className="xl:h-450 justify-center items-center text-center"></img>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="">
          <div className="xl:pt-0 lg:pt-0 md:pt-16 xl:pb-0 xl:block lg:block md:block block">
            <div className="container mx-auto xl:px-12">
              <div className="flex flex-wrap items-center xl:pt-12 lg:pt-0 md:pt-2 pt-3 xl:pl-12 lg:pl-0 md:pl-0 pl-4">
                <div className="w-12/12 justify-center items-center pr-4 pt-12 text-center xl:hidden lg:hidden md:hidden block sm:block" style={{ textAlign: "-webkit-center" }}>
                  <img src="https://i.ibb.co/LhscfRm/mobile02.png" className="justify-center items-center text-center w-auto"></img>
                </div>
                <div className="xl:w-6/12 lg:w-6/12 md:w-5/12 ">
                  <div className="text-left z-50 ">
                    <h2 className="hometext uppercase font-boldd xl:text-4xl lg:text-2xl text-base text-ivory-black xl:mt-16 lg:mt-28 lg:ml-2 mt-6">
                      Pruthvi Villas
                    </h2>
                    <div className="xl:block lg:block md:block block">
                      <p className=" paratext xl:text-base lg:text-base text-sm text-ivory-black leading-relaxed xl:pt-4 lg:ml-2 lg:pt-4">
                        Our approach involves design, landscaping, and infrastructure, taking community living to the next level and setting us apart in the market.
                      </p>
                    </div>
                    <div className="xl:pt-4 lg:pt-4">
                      <HashLink smooth to="/#enquiryy">
                        <button className="text-white gradient2 xl:text-sm text-xs xl:px-12 lg:ml-2 px-8 py-2 mt-2 rounded font-semibold uppercase xl:py-2  bg-lime-500">
                          Get Info
                        </button>
                      </HashLink>
                      <a href="/project-homepage">
                        <button className="text-white gradient2 xl:text-sm text-xs xl:px-12 lg:ml-2 md:ml-2 ml-2 px-8 py-2 mt-2 rounded font-semibold uppercase xl:ml-4 xl:py-2 bg-lime-500">
                          Projects
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="xl:pl-24   xl:w-6/12 lg:w-6/12 md:w-6/12 justify-center items-center text-center xl:pt-4 lg:pt-24 xl:block lg:block md:block hidden sm:hidden">
                  <img src="https://i.ibb.co/stGvZN8/pruthvivillas-min.png" className="xl:h-450 justify-center items-center text-center"></img>
                </div>
              </div>
            </div>
          </div>
        </div>




        {/* <div className="">
          <div className="xl:pt-6 lg:pt-12 md:pt-16 xl:block lg:block md:block block">
            <section
              style={{
                // backgroundImage: `url("https://i.ibb.co/2ZHcRzN/pruthvibanner.jpg")`,
                backgroundImage: `url("https://i.ibb.co/0hMLQ75/banner-01-1.png")`,
                width: "100%",
                backgroundSize: "cover",
                // height: "600px",
                backgroundPosition: "center",
                backgroundColor: "black",
              }}
              className="xl:h-600 lg:h-450 md:h-300 h-300"
            >
              <div className="xl:px-16 px-4 overflow-hidden container mx-auto">
                <div className="text-left z-50 xl:pt-40 md:pt-12 pt-24">
                  <h2 className="hometext uppercase font-boldd xl:text-4xl lg:text-2xl text-base text-ivory-black xl:mt-16 lg:mt-28 lg:ml-2 mt-12">
                    Pruthvi Paradise
                  </h2>
                  <div className="xl:block lg:hidden md:hidden hidden">
                    <p className=" paratext xl:text-base lg:text-base text-sm text-ivory-black leading-relaxed xl:pt-4 lg:ml-2 lg:pt-4">
                      Pruthvi Projects introducing Pruthvi Paradise , for people who <br></br>are looking for quality & luxury life.
                    </p>
                  </div>

                  <div className="xl:hidden lg:block md:hidden hidden">
                    <p className=" paratext xl:text-base lg:text-base text-sm text-ivory-black leading-relaxed xl:pt-4 lg:ml-2 lg:pt-4">
                      Pruthvi Projects introducing Pruthvi Paradise , for people who <br></br>are looking for quality & luxury life.
                    </p>
                  </div>

                  <div className="xl:hidden lg:hidden md:block hidden">
                    <p className=" paratext xl:text-xl lg:text-sm md:text-sm text-sm text-ivory-black leading-relaxed xl:pt-4 md:pt-2 lg:ml-2 lg:pt-4">
                      Pruthvi Projects introducing Pruthvi Paradise , for people who <br></br>are looking for quality & luxury life.
                    </p>
                  </div>

                  <div className="xl:hidden lg:hidden md:hidden block">
                    <p className=" paratext xl:text-xl lg:text-sm md:text-sm text-xs text-ivory-black leading-relaxed xl:pt-4 md:pt-2 lg:ml-2 lg:pt-4">
                      Pruthvi Projects introducing Pruthvi <br></br>Paradise , for people who are <br></br>looking for quality & luxury life.
                    </p>
                  </div>

                  <div className="xl:pt-4 lg:pt-4">
                    <HashLink smooth to="/#enquiryy">
                      <button className="text-white gradient2 xl:text-sm text-sm xl:px-12 lg:ml-2 px-4 py-2 mt-2 rounded-md font-semibold uppercase xl:py-2  bg-lime-500">
                        Get Info
                      </button>
                    </HashLink>
                    <a href="/project-homepage">
                      <button className="text-white gradient2 xl:text-sm text-sm xl:px-12 lg:ml-2 md:ml-2 ml-2 px-4 py-2 mt-2 rounded-md font-semibold uppercase xl:ml-4 xl:py-2 bg-lime-500">
                        Projects
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div> */}



        {/* <div className="">
          <div className="xl:pt-6 lg:pt-12 md:pt-16 xl:block lg:block md:block block">
            <section
              style={{
                // backgroundImage: `url("https://i.ibb.co/2ZHcRzN/pruthvibanner.jpg")`,
                backgroundImage: `url("https://i.ibb.co/m8Rvybp/banner-03-1.png")`,
                width: "100%",
                backgroundSize: "cover",
                // height: "600px",
                backgroundPosition: "center",
                backgroundColor: "black",
              }}
              className="xl:h-600 lg:h-450 md:h-300 h-300"
            >
              <div className="xl:px-16 px-4 overflow-hidden container mx-auto">
                <div className="text-left z-50 xl:pt-40 md:pt-12 pt-24">
                  <h2 className="hometext uppercase font-boldd xl:text-4xl lg:text-2xl text-base text-ivory-black xl:mt-16 lg:mt-28 lg:ml-2 mt-12">
                    Pruthvi Assets
                  </h2>
                  <div className="xl:block lg:hidden md:hidden hidden">
                    <p className=" paratext xl:text-base lg:text-base text-sm text-ivory-black leading-relaxed xl:pt-4 lg:ml-2 lg:pt-4">
                      Investing in our project could generate huge profits in the <br></br>future, as it has become the most
                      preferred residential location <br></br>for the inhabitants of Bangalore.
                    </p>
                  </div>

                  <div className="xl:hidden lg:block md:hidden hidden">
                    <p className=" paratext xl:text-base lg:text-base text-sm text-ivory-black leading-relaxed xl:pt-4 lg:ml-2 lg:pt-4">
                      Investing in our project could generate huge profits in the <br></br>future, as it has become the most
                      preferred residential location <br></br>for the inhabitants of Bangalore.
                    </p>
                  </div>

                  <div className="xl:hidden lg:hidden md:block hidden">
                    <p className=" paratext xl:text-xl lg:text-sm md:text-sm text-sm text-ivory-black leading-relaxed xl:pt-4 md:pt-2 lg:ml-2 lg:pt-4">
                      Investing in our project could generate huge profits in the <br></br>future, as it has become the most
                      preferred residential location <br></br>for the inhabitants of Bangalore.
                    </p>
                  </div>

                  <div className="xl:hidden lg:hidden md:hidden block">
                    <p className=" paratext xl:text-xl lg:text-sm md:text-sm text-xs text-ivory-black leading-relaxed xl:pt-4 md:pt-2 lg:ml-2 lg:pt-4">
                      Investing in our project could generate huge <br></br>profits in the future, as it has become<br></br> the most
                      preferred residential location <br></br>for the inhabitants of Bangalore.
                    </p>
                  </div>

                  <div className="xl:pt-4 lg:pt-4">
                    <HashLink smooth to="/#enquiryy">
                      <button className="text-white gradient2 xl:text-sm text-sm xl:px-12 lg:ml-2 px-4 py-2 mt-2 rounded-md font-semibold uppercase xl:py-2  bg-lime-500">
                        Get Info
                      </button>
                    </HashLink>
                    <a href="/project-homepage">
                      <button className="text-white gradient2 xl:text-sm text-sm xl:px-12 lg:ml-2 md:ml-2 ml-2 px-4 py-2 mt-2 rounded-md font-semibold uppercase xl:ml-4 xl:py-2 bg-lime-500">
                        Projects
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div> */}

      </Slider>



      {/* 2nd Section */}

      <section className="bg-grey-100 xl:pb-4 lg:pb-12 md:pb-4 pb-4  mt-6">
        <div className=" container mx-auto xl:px-16 px-4 xl:pb-12 pb-4 lg:pt-12 md:pb-10 xl:pt-14 md:pt-8 pt-8">
          <h1 className="text-center text-black hometext xl:text-4xl lg:text-3xl md:text-2xl text-xl font-semibold">
            Discover Our Featured Listings
          </h1>
          <p className="paratext xl:text-base lg:text-base md:text-sm text-xs text-center font-light xl:pt-3 lg:mt-1 pb-6 pt-2">
            Crafting Your Perfect Heaven, Beyond Imagination
          </p>

          {/* <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 md:gap-4 pt-4 xl:pt-6 lg:mt-2 md:pt-4 lg:ml-2 xl:gap-3 lg:gap-3"> */}
          <Slider {...settings1}>
            <div className="px-1">
              <div className="bg-white shadow-sm rounded-md ">
                <a href="/project-page">
                  <img
                    src="https://i.ibb.co/KWJJvvf/pruthvihome.jpg"
                    className="h-48 rounded-t-md"
                  ></img></a>
                <div className="xl:pt-6 p-4 lg:pt-2">
                  <h4 className="hometext xl:text-lg font-semibold">
                    Pruthvi Homes
                  </h4>
                  <div>
                    <div className="flex">
                      <img
                        src={mapmarker}
                        className="h-4 w-4 xl:mt-5 lg:mt-2 md:mt-3 mt-1"
                      ></img>
                      <p className="xl:text-sm lg:text-sm font xl:mt-4 ml-2 md:mt-2 xl:h-16 lg:mt-2">
                        38/A, Dyavasandra Village, Haragadde Post, Anekal Taluk, Bangalore Urban, Karnataka- 560099
                      </p>
                    </div>

                    <div className="flex xl:pt-8 lg:pt-6 md:pt-4 pt-12">
                      <div className="flex items-center">
                        <img src="https://i.ibb.co/fq3Pz86/double-bed.png" className=" w-4"></img>
                        <p className="text-xs paratext pl-2">2 BHK</p>

                        <div className="flex items-center pl-3">
                          <img src="https://i.ibb.co/rcDZHYB/hotel.png" className=" w-4"></img>
                          <p className="text-xs paratext pl-1">2</p>

                          <div className="flex items-center pl-3">
                            <img src="https://i.ibb.co/2qPbj0D/parking.png" className=" w-4"></img>
                            <p className="text-xs paratext pl-2">1</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>

                <div className="">
                  <div className=" flex justify-between items-center rounded-b-md bg-slate-200 p-4 p-4">
                    <div className="">
                      <a href="/project-page">
                        <button className="gradient2 button font-semibold items-center xl:text-sm lg:text-sm xl:mt-1 ">
                          View Details
                        </button>
                      </a>
                    </div>

                    <div className="text-sm paratext xl:mt-1 ">
                      {/* <p className="font-semibold xl:text-xs md:text-base text-base">
                        Sqft
                      </p>
                      <p className=" xl:text-2xl md:text-lg text-xl font-bold">
                        1000
                      </p> */}
                      <button onClick={handleButtonClickCall}>
                        <i className="fas fa-phone p-2 bg-white rounded-full ml-3"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-1">
              <div className="bg-white shadow-sm rounded-md ">
                <a href="/project-page1">
                  <img
                    src="https://i.ibb.co/93GVvKP/Pruthivi-assets-crd.png"
                    className="h-48 rounded-t-md"
                  ></img></a>
                <div className="xl:pt-6 p-4 lg:pt-2">
                  <h4 className="hometext xl:text-lg font-semibold">
                    Pruthvi Assets
                  </h4>
                  <div>
                    <div className="flex">
                      <img
                        src={mapmarker}
                        className="h-4 w-4 xl:mt-5 lg:mt-2 md:mt-3 mt-1"
                      ></img>
                      <p className="xl:text-sm lg:text-sm font xl:mt-4 md:mt-2 ml-2 xl:h-16 lg:mt-2">
                        Sarjapura Hobli, Kuthaganahalli village, Dc conversation land Spread across 7.5 acres of land.
                      </p>
                    </div>

                    <div className="flex xl:pt-6 lg:pt-6 md:pt-4 pt-10">
                      <div className="flex items-center">
                        <img src="https://i.ibb.co/P9GY17V/plotting.png" className=" w-6"></img>
                        <p className="text-xs paratext pl-2">1200 sq ft</p>

                        {/* <div className="flex items-center pl-3">
                          <img src="https://i.ibb.co/rcDZHYB/hotel.png" className=" w-5"></img>
                        <p className="text-xs paratext pl-1">2</p>

                          <div className="flex items-center pl-3">
                          <img src="https://i.ibb.co/2qPbj0D/parking.png" className=" w-5"></img>
                        <p className="text-xs paratext pl-2">2</p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>{" "}
                </div>

                <div className="">
                  <div className="items-center justify-between  flex rounded-b-md bg-slate-200 p-4">
                    <div className="">
                      <a href="/project-page1">
                        <button className="gradient2 button font-semibold items-center xl:text-sm lg:text-sm xl:mt-1 ">
                          View Details
                        </button>
                      </a>
                    </div>

                    <div className="text-sm paratext xl:mt-1 ">
                      {/* <p className="font-semibold xl:text-xs md:text-base text-base">
                        Sqft
                      </p>
                      <p className=" xl:text-2xl md:text-lg text-xl font-bold">
                        1000
                      </p> */}
                      <button onClick={handleButtonClickCall}>
                        <i className="fas fa-phone p-2 bg-white rounded-full ml-3"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-1">
              <div className="bg-white shadow-sm rounded-md ">
                <a href="/project-page2">
                  <img
                    src="https://i.ibb.co/1fqvHQh/Pruthivi-pardise-crd.png"
                    className="h-48 rounded-t-md"
                  ></img></a>
                <div className="xl:pt-6 p-4 lg:pt-2">
                  <h4 className="hometext xl:text-lg font-semibold">
                    Pruthvi Paradise
                  </h4>
                  <div>
                    <div className="flex">
                      <img
                        src={mapmarker}
                        className="h-4 w-4 xl:mt-5 lg:mt-2 md:mt-3 mt-1"
                      ></img>
                      <p className="xl:text-sm lg:text-sm font xl:mt-4 md:mt-2 ml-2 xl:h-16 lg:mt-2">
                        Pruthvi paradise is a BMRDA residential plots located in Jigani,Bangalore.
                      </p>
                    </div>

                    <div className="flex xl:pt-6 lg:pt-6 md:pt-4 pt-16">
                      <div className="flex items-center">
                        <img src="https://i.ibb.co/P9GY17V/plotting.png" className=" w-6"></img>
                        <p className="text-xs paratext pl-2">1200 sq ft</p>

                        {/* <div className="flex items-center pl-3">
                          <img src="https://i.ibb.co/rcDZHYB/hotel.png" className=" w-5"></img>
                        <p className="text-xs paratext pl-1">2</p>

                          <div className="flex items-center pl-3">
                          <img src="https://i.ibb.co/2qPbj0D/parking.png" className=" w-5"></img>
                        <p className="text-xs paratext pl-2">2</p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>{" "}
                </div>

                <div className="">
                  <div className="items-center justify-between flex rounded-b-md bg-slate-200 p-4">
                    <div className="">
                      <a href="/project-page2">
                        <button className="gradient2 button font-semibold items-center xl:text-sm lg:text-sm xl:mt-1 ">
                          View Details
                        </button>
                      </a>
                    </div>

                    <div className="text-sm paratext xl:mt-1 ">
                      {/* <p className="font-semibold xl:text-xs md:text-base text-base">
                        Sqft
                      </p>
                      <p className=" xl:text-2xl md:text-lg text-xl font-bold">
                        1000
                      </p> */}
                      <button onClick={handleButtonClickCall}>
                        <i className="fas fa-phone p-2 bg-white rounded-full ml-3"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="px-1">
              <div className="bg-white shadow-sm rounded-md ">
                <a href="/project-page3">
                  <img
                    src="https://i.ibb.co/j46YXxf/Pruthivi-prime-crd.png"
                    className="h-48 rounded-t-md"
                  ></img>
                </a>
                <div className="xl:pt-6 p-4 lg:pt-2">
                  <h4 className="hometext xl:text-lg font-semibold">
                    Pruthvi Prime
                  </h4>
                  <div>
                    <div className="flex">
                      <img
                        src={mapmarker}
                        className="h-4 w-4 xl:mt-5 lg:mt-2 md:mt-3 mt-1"
                      ></img>
                      <p className="xl:text-sm lg:text-sm font xl:mt-4 md:mt-2 ml-2 xl:h-16 lg:mt-2">
                        Grand Villa Plots- Near Bescom, Sarjapura Town
                      </p>
                    </div>

                    <div className="flex xl:pt-6 lg:pt-6 md:pt-4 pt-16">
                      <div className="flex items-center">
                        <img src="https://i.ibb.co/P9GY17V/plotting.png" className=" w-6"></img>
                        <p className="text-xs paratext pl-2">1200 sq ft</p>

                        {/* <div className="flex items-center pl-3">
                          <img src="https://i.ibb.co/rcDZHYB/hotel.png" className=" w-5"></img>
                        <p className="text-xs paratext pl-1">2</p>

                          <div className="flex items-center pl-3">
                          <img src="https://i.ibb.co/2qPbj0D/parking.png" className=" w-5"></img>
                        <p className="text-xs paratext pl-2">2</p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>{" "}
                </div>

                <div className="">
                  <div className=" justify-between items-center flex rounded-b-md bg-slate-200 p-4">
                    <div className="">
                      <a href="/project-page3">
                        <button className="gradient2 button font-semibold items-center xl:text-sm lg:text-sm xl:mt-1 ">
                          View Details
                        </button>
                      </a>
                    </div>

                    <div className="text-sm paratext xl:mt-1 ">
                      {/* <p className="font-semibold xl:text-xs md:text-base text-base">
                        Sqft
                      </p>
                      <p className=" xl:text-2xl md:text-lg text-xl font-bold">
                        1000
                      </p> */}
                      <button onClick={handleButtonClickCall}>
                        <i className="fas fa-phone p-2 bg-white rounded-full ml-3"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="px-1">
              <div className="bg-white shadow-sm rounded-md ">
                <a href="">
                  <img
                    src={projectvivanta}
                    className="h-48 rounded-t-md"
                  ></img>
                </a>
                <div className="xl:pt-6 p-4 lg:pt-2">
                  <h4 className="hometext xl:text-lg font-semibold">
                    Pruthvi Vivanta
                  </h4>
                  <div>
                    <div className="">
                      {/* <img
                        src={mapmarker}
                        className="h-4 w-4 xl:mt-5 lg:mt-2 md:mt-3 mt-1"
                      ></img> */}
                      <p className="xl:text-sm lg:text-sm font xl:mt-4 md:mt-2 ml-2 xl:h-16 lg:mt-2">
                        Our approach involves design, infrastructure, taking community living to the next level & setting us apart in the market.
                      </p>
                    </div>

                    <div className=" xl:pt-6 lg:pt-6 md:pt-4 pt-10">
                      <h4 className="hometext xl:text-sm font-semibold">
                        Pruthvi Vivanta Coming Soon..
                      </h4>
                      {/* <div className="flex items-center">
                        <i className="fas fa-bed p-2 bg-light rounded-full"></i>
                        <p className="text-base paratext pl-2">3</p>

                        <div className="flex items-center">
                          <i className="fas fa-shower p-2 bg-light rounded-full ml-3"></i>
                          <p className="text-base paratext pl-2">1</p>

                          <div className="flex items-center">
                            <i className="fas fa-car p-2 bg-light rounded-full ml-3"></i>
                            <p className="text-base paratext pl-2">1</p>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>{" "}
                </div>

                <div className="">
                  <div className=" justify-between items-center flex rounded-b-md bg-slate-200 p-4">
                    <div className="">
                      <a href="/contact">
                        <button className="gradient2 button font-semibold items-center xl:text-sm lg:text-sm xl:mt-1 ">
                          View Details
                        </button>
                      </a>
                    </div>

                    <div className="text-sm paratext xl:mt-1 ">
                      {/* <p className="font-semibold xl:text-xs md:text-base text-base">
                        Sqft
                      </p>
                      <p className=" xl:text-2xl md:text-lg text-xl font-bold">
                        1000
                      </p> */}
                      <button onClick={handleButtonClickCall}>
                        <i className="fas fa-phone p-2 bg-white rounded-full ml-3"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>





      {/* 3rd section */}

      <section>
        <div className="xl:block lg:block md:block hidden mb-12">
          <section
            style={{
              position: "relative",
              backgroundImage: `url(${bgpruthviii})`,
              backgroundAttachment: "fixed", // Set background attachment to fixed
              width: "100%",
              // height: "730px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="xl:h-730 lg:h-550 md:h-590 flex justify-center text-white"
          >
            <div className="container mx-auto overflow-hidden hometext xl:px-16 lg:mt-12 px-4">
              <div className="text-left">
                <div className="flex xl:w-12/12">
                  <div className="xl:w-7/12">
                    <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 xl:gap-4 lg:gap-4">
                      <div className="grid xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1">
                        <div className="xl:text-3xl lg:text-2xl xl:ml-6 lg:ml-2 xl:mt-16 lg:mt-8 md:mt-8 mt-8">
                          <div className="">
                            Why Pruthvi Is The <br></br>Perfect Choice?
                            <div className="bordertb xl:mb-42 lg:pb-37 md:pb-4"></div>
                          </div>
                        </div>

                        <div className="xl:block lg:block md:hiddden hidden">
                          <div className=" xl:text-2xl xl:ml-6 xl:mt-18 lg:ml-2 md:mt-4 lg:mt-16">
                            <p className="hometext xl:text-5xl lg:text-4xl md:text-2xl">
                              02.
                            </p>
                            <br></br>
                            <p className="hometext xl:text-lg lg:text-lg font-bold">
                              Pruthvi Home
                            </p>{" "}
                            <p className="md:w-12/12 xl:w-12/12 xl:text-sm xl:mb-20 md:text-sm xl:mt-2 lg:mt-2">
                              Class living in peaceful and tranquil neighborhoods, where fresh air,
                              abundant water & blissful silence of countryside
                            </p>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="xl:mt-16">
                          <div className="xl:text-2xl xl:ml-6 lg:mt-7 md:mt-4">
                            <p className="hometext xl:text-5xl lg:text-4xl md:text-2xl">
                              01.
                            </p>
                            <br></br>
                            <p className="hometext xl:text-lg lg:text-lg font-bold">
                              Pruthvi Paradise
                            </p>{" "}
                            <p className="xl:w-12/12 lg:w-8/12 md:w-12/12 xl:text-sm lg:text-sm md:text-xs md:pt-2 xl:mb-20 xl:mt-2 lg:mt-2 lg:mb-12 md:mb-4">
                              Our layouts are ECO friendly causing least burden on the natural resources.
                            </p>
                          </div>
                          <div className="bordertb xl:ml-8"></div>

                          <div className="xl:hidden lg:hidden md:block block">
                            <div className=" xl:text-2xl xl:ml-6 xl:mt-18 lg:ml-2 md:mt-4 lg:mt-16">
                              <p className="hometext xl:text-5xl lg:text-4xl md:text-2xl">
                                02.
                              </p>
                              <br></br>
                              <p className="hometext xl:text-lg font-bold">
                                Pruthvi Home
                              </p>
                              <p className="md:w-12/12 xl:w-12/12 xl:text-base md:text-xs md:mt-2 xl:mb-20 xl:mt-2 lg:mt-2">
                                Class living in peaceful and tranquil neighborhoods, where fresh air,
                                abundant water & blissful silence of countryside
                              </p>
                            </div><div className="bordertb xl:ml-8 md:mt-4"></div>
                          </div>

                          <div className="xl:text-2xl xl:ml-6 xl:mt-16 lg:mt-16 md:mt-4">
                            <p className="hometext xl:text-5xl xl:pt-2 lg:text-4xl md:text-2xl">
                              03.
                            </p>
                            <br></br>
                            <p className="hometext xl:text-lg lg:text-lg font-bold">
                              Pruthvi Asset
                            </p>{" "}
                            <p className="xl:text-sm lg:text-sm md:text-xs xl:mt-2 md:mt-2 lg:mt-2 ">
                              Our project decisions are backed by extensive market research,
                              ensuring the delivery of high-quality products.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="xl:w-5/12 xl:mt-8 md:mt-24 lg:mt-0 xl:ml-4" id="enquiryy">
                    <div className="bg-white rounded-md xl:p-5 lg:p-5 md:p-4">
                      <form ref={form} onSubmit={sendEmail} className="text-black " >
                        <div className="xl:text-2xl lg:text-xl md:text-lg font font-semibold  hometext">
                          Real Estate Enquiry Form <br></br>
                          <p className="xl:text-base lg:text-sm md:text-sm text-sm font-normal font xl:mt-2">
                            Contact us today if you’d like to know more about how we help buy, or rent your home
                          </p>
                        </div>

                        <div className="grid grid-rows-4 xl:gap-4 md:gap-2 sxl:mt-4 md:mt-4 lg:gap-2 lg:mt-2">
                          <input
                            type="text"
                            className="pl-4 rounded font border-light-grey h-10 md:text-xs xl:text-base lg:text-base text-xs"
                            placeholder="Enter Name"
                            name="fullName"
                            maxlength="35" onChange={event => setFullName(event.target.value)}
                            value={fullName} required
                          />
                          <input
                            className="pl-4 rounded font border-light-grey h-10 md:text-xs xl:text-base lg:text-base text-xs"
                            type="tel"
                            placeholder="Enter Phone Number"
                            name="phoneNumber"
                            value={phoneNumber}
                            onChange={event => setPhoneNumber(event.target.value)} required
                          ></input>
                          <input
                            className="pl-4 rounded font border-light-grey h-10 md:text-xs xl:text-base lg:text-base text-xs"
                            name="email"
                            type="email"
                            placeholder="Enter Email"
                            value={email}
                            onChange={event => setEmail(event.target.value)} required
                          ></input>
                        </div>

                        <div className="grid lg:grid-rows-1 mt-2 lg:mt-2">
                          <select
                            id="EnquiryType"
                            className="xl:mt-2 font rounded-md border-light-grey w-full h-10 px-4"
                            value={project} name="project"
                            onChange={event => setProject(event.target.value)} required // Make project selection required
                          >
                            <option value="">Select</option>
                            <option value="Pruthvi Home">Pruthvi Home</option>
                            <option value="Pruthvi Home">Pruthvi Asset</option>
                            <option value="Pruthvi Paradise">Pruthvi Paradise</option>
                            <option value="Pruthvi Prime">Pruthvi Prime</option>
                          </select>
                        </div>

                        <div className="grid grid-rows-1 xl:gap-4 mt-4">
                          <textarea
                            className="pl-4 rounded font border-light-grey h-20 md:text-xs xl:text-base lg:text-base text-xs"
                            placeholder="Hello, I am interested in [Design apartment]"
                            value={message}
                            name="message"
                            onChange={event => setMessage(event.target.value)} required></textarea>

                        </div>

                        {/* <div className="xl:mt-4">
                        <input
                          type="checkbox"
                          className="xl:ml-2 form-checkbox h-3 w-3 text-blue-950 "
                        />
                        <span className="xl:ml-4 text-blue-950 font">
                          By submitting this form I agree to Terms of Use
                        </span>
                      </div> */}

                        <div className="grid grid-cols-1 xl:gap-4 lg:gap-2 md:gap-2 xl:mt-8 md:mt-2 lg:mt-2">
                          <button className="font gradient2 rounded border text-white lg:text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2"
                            type="submit"
                            value="submit">
                            Send Message
                          </button>

                        </div>
                      </form>
                      <div className="grid grid-cols-2 gap-2  xl:mt-4 lg:mt-2 md:mt-1 mt-2">
                        <button className="rounded gradient2 border text-white lg:text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2">
                          <a href="https://wa.me/+919900533422">
                            <div className="flex justify-center items-center">
                              <img src={whatsapp} className="w-4 h-4  mr-2" />
                              Whats App
                            </div>
                          </a>
                        </button>
                        <a href="tel:+919900533422" className="">
                          <button className="w-12/12 font gradient2 rounded border text-white  lg:text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2">
                            Call
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>





      {/* About Us */}

      <section className="container mx-auto pt-12 lg:pt-6 pb-20">
        <h1 className="text-center text-black hometext xl:text-4xl lg:text-3xl md:text-2xl text-xl font-semibold">
          About Pruthvi
        </h1>
        <p className="paratext xl:text-base lg:text-base md:text-sm text-xs text-center font-light xl:pt-3 lg:mt-1 pb-6 pt-2">
          Examine the
          Magnificence of Motivated by Design Tasks
        </p>
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2 items-center">
          <div className="">
            <div
              className={`content ${hoveredItem === 1 ? 'mt-4 hovered xl:text-2xl lg:text-2xl text-base font text-black' : 'mt-4 xl:text-2xl lg:text-2xl text-base  font text-black'}`}
              onMouseEnter={() => setHoveredItem(1)}
              onMouseLeave={() => setHoveredItem(null)} // Reset hoveredItem on leave
            >
              Unparalleled Design: Spaces meticulously curated with a steadfast dedication
              to beauty, practicality, and luxury.
            </div>
            <div
              className={`content ${hoveredItem === 2 ? 'mt-4 hovered xl:text-2xl lg:text-2xl text-base  font text-black' : 'mt-4 xl:text-2xl lg:text-2xl text-base  font text-black'}`}
              onMouseEnter={() => setHoveredItem(2)}
              onMouseLeave={() => setHoveredItem(null)} // Reset hoveredItem on leave
            >
              Proficiency: Extensive expertise and profound market insights to empower informed decision-making.
            </div>
            <div
              className={`content ${hoveredItem === 3 ? 'mt-4 hovered xl:text-2xl lg:text-2xl text-base  font text-black' : 'mt-4 xl:text-2xl lg:text-2xl text-base  font text-black'}`}
              onMouseEnter={() => setHoveredItem(3)}
              onMouseLeave={() => setHoveredItem(null)} // Reset hoveredItem on leave
            >
              Customer-Focused Methodology: Outstanding service and enduring partnerships.
            </div>
          </div>

          <div className="">
            <img
              className={`image ${hoveredItem === 1 || hoveredItem === null ? 'px-6 rounded-lg xl:h-400' : 'px-6  xl:h-400 hidden'}`}
              src="https://i.ibb.co/fM16Jyw/pruthviimages.png"
              alt="Image 1"
            />
            <img
              className={`image ${hoveredItem === 2 ? 'px-6  rounded-lg xl:h-400' : 'px-6  xl:h-400 hidden'}`}
              src="https://i.ibb.co/SwKXDJR/pruthvi2.jpg"
              alt="Image 2"
            />
            <img
              className={`image ${hoveredItem === 3 ? 'px-6  rounded-lg xl:h-400' : 'px-6  xl:h-400 hidden'}`}
              src="https://i.ibb.co/4ZG7V9t/pruthvi3.jpg"
              alt="Image 3"
            />
          </div>
        </div>
      </section>



      {/* Design Section  */}




      <SliderPage />


      <section className="container overflow-hidden mx-auto xl:px-16 px-4 xl:mb-12 lg:mb-12 md:mb-12">
        <div class="construction-container mb-8">
          <img src={vivanta} class="rounded-md w-full h-200"></img>
          <div className="overlay-text2 font-bold xl:text-3xl -mt-16 hometext"> Villas Coming Soon
          </div>

        </div>
      </section>
      <Footer />
    </section>
  );
}
