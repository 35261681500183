import React, { useState, useRef } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footers/Footer";
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  position: toast.POSITION.TOP_CENTER,
  autoClose: 3000,
  maxWidth: '800px',
})


const Contactus = () => {


  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [project, setProject] = useState('');


  const form = useRef();
  const sendEmail = (e) => {

    emailjs.sendForm('service_re0yn5q', 'template_xdi9r9r', form.current, 'klAgwhrXKwJEHK3q7')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });

    toast('Thank you for contacting Pruthvi Projects. We have received your enquiry and will respond to you as soon as possible. ')
    e.preventDefault(); // prevent page refresh

    //  clear all input values in the form
    setFullName('');
    setEmail('');
    setPhoneNumber('');
    setMessage('');
    setProject('');

  };


  return (
    <section>
      <IndexNavbar fixed />
      <div className="xl:pt-18 lg:pt-16 pt-4">
        <section
          style={{
            // backgroundImage: `url("https://i.ibb.co/R0kT0vj/pruthvii.png")`,
            backgroundImage: `url("https://i.ibb.co/R0kT0vj/pruthvii.png")`,
            width: "100%",
            backgroundSize: "cover",
            // height: "200px",
            backgroundPosition: "center",
            backgroundColor: "black",
          }}
          className="xl:h-60 lg:h-220 md:h-240 h-48"
        >
          <div className="hometext text-white text-center xl:text-3xl text-2xl font-semibold xl:pt-26 pt-24 lg:pt-28 md:pt-36">
            Contact Us
          </div>
        </section>

        <div className="bg-grey-100">
          <section className="container mx-auto overflow-hidden xl:px-16 px-4">
            <div className="">
              <div className="flex-none xl:flex lg:flex md:flex xl:w-12/12 xl:gap-6 lg:gap-4 md:gap-4 lg:w-12/12 md:w-12/12 w-12/12">
                <div className="xl:w-8/12 lg:w-8/12 md:w-8/12 w-12/12">
                  <div className="hometext xl:text-xl text-lg md:text-base font font-semibold xl:pt-20 xl:pb-4 pt-4 pb-4">
                    Get In Touch With Us
                  </div>
                  <form ref={form} onSubmit={sendEmail} className="bg-white rounded-md xl:p-8 p-4 md:p-4 lg:p-4 xl:mb-12 md:mb-8 mb-0">
                    <div className="grid xl:grid-cols-2 xl:gap-4 lg:gap-4 md:gap-4 lg:grid-cols-2 md:grid-cols-2 grid-cols-1">
                      <div>
                        <label className="text-base font font-semibold">
                          Name
                          <span className="text-red-500 ml-1">*</span>{" "}
                        </label>
                        <br></br>
                        <input
                          className="xl:mt-2 font rounded-md border-light-grey w-full h-12"
                          type="text"
                          placeholder="Enter Name"
                          name="fullName"
                          maxlength="25" onChange={event => setFullName(event.target.value)}
                          value={fullName} required
                        ></input>
                      </div>

                      <div className=" xl:pt-0 md:pt-0 pt-4">
                        <label className="text-base font font-semibold">
                          Phone Number
                          <span className="text-red-500 ml-1">*</span>{" "}
                        </label>
                        <br></br>
                        <input
                          className="xl:mt-2 font rounded-md border-light-grey w-full h-12 px-4"
                          type="tel"
                          placeholder="Enter Phone Number"
                          name="phoneNumber"
                          value={phoneNumber}
                          onChange={event => setPhoneNumber(event.target.value)} required
                        ></input>
                      </div>
                    </div>

                    <div className="grid xl:grid-cols-2 xl:gap-4 lg:gap-4 md:gap-4 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 xl:pt-4 lg:pt-4 md:pt-4 pt-4">
                      <div>
                        <label className="text-base font font-semibold">
                          Email <span className="text-red-500 ml-1">*</span>{" "}
                        </label>
                        <br></br>
                        <input
                          className="xl:mt-2 font rounded-md border-light-grey w-full h-12 px-4"
                          name="email"
                          type="email"
                          placeholder="Enter Email"
                          value={email}
                          onChange={event => setEmail(event.target.value)} required
                        ></input>
                      </div>

                      <div className="xl:pt-0 md:pt-0 pt-4">
                        <label className="text-base font font-semibold ">
                          Project <span className="text-red-500 ml-1">*</span>{" "}
                        </label>
                        <br></br>
                        <select
                          id="EnquiryType"
                          className="xl:mt-2 font rounded-md border-light-grey w-full h-12 px-4"
                          value={project} name="project"
                          onChange={event => setProject(event.target.value)} required // Make project selection required
                        >
                          <option value="">Select</option>
                          <option value="Pruthvi Home">Pruthvi Home</option>
                          <option value="Pruthvi Home">Pruthvi Asset</option>
                          <option value="Pruthvi Paradise">Pruthvi Paradise</option>
                          <option value="Pruthvi Prime">Pruthvi Prime</option>
                        </select>
                      </div>
                    </div>

                    <div className="grid xl:grid-cols-1 xl:gap-4 lg:gap-4 md:gap-4 lg:grid-cols-1 md:grid-cols-1 grid-cols-1 xl:pt-6 lg:pt-4 md:pt-4 pt-4">
                      <div>
                        <label className="text-base font font-semibold">
                          Description
                        </label>
                        <br></br>
                        <textarea
                          name="message"
                          className="xl:mt-2 font rounded-md border-light-grey w-full h-20 px-4"
                          placeholder="Drop us a small Description"
                          value={message}
                          onChange={event => setMessage(event.target.value)} required></textarea>

                      </div>
                    </div>

                    {/* <div className="xl:pt-4 lg:pt-6 md:pt-4 pt-4">
                      <label className="text-base font font-semibold">
                        GDPR Agreement{" "}
                        <span className="text-red-500 ml-1">*</span>{" "}
                      </label>
                      <div className="xl:mt-4">
                        <input
                          type="checkbox"
                          className="form-checkbox h-3 w-3 text-blue-950 "
                        />
                        <span className="ml-4 text-blue-950 font xl:text-lg">
                          I consent to having this website store my submitted
                          information
                        </span>
                      </div>
                    </div> */}

                    <button className="rounded-md font mt-4 xl:py-2 py-2 font-semibold text-white uppercase w-full gradient2"
                      type="submit"
                      value="submit">
                      submit
                    </button>
                  </form>
                </div>
                <div className="xl:w-4/12 lg:w-4/12 md:w-4/12 w-12/12 xl:pt-32 lg:pt-18 md:pt-26 xl:mt-0 lg:mt-0 md:mt-0 mt-4 pb-4">
                  <div className="bg-white rounded-md xl:p-8 p-4 md:p-4 lg:p-4 xl:mb-12">
                    <div className="xl:pt-3">
                      <p className="xl:text-lg text-lg md:text-base font font-semibold hometext">
                        For inquiries contact:
                      </p>
                      <br></br>
                      <p className="hometext xl:text-base text-base md:text-sm font font-semibold -mt-4">
                        Address
                      </p>
                      <p className="xl:text-base font  text-sm md:text-sm pt-1">
                        No. 645, Fourth Floor, Zeal square, 12th cross, 27th Main Rd,
                        behind cafe coffee day, 1st Sector, HSR Layout, Bengaluru, Karnataka 560102.
                      </p>
                    </div>

                    <div>
                      <p className="hometext xl:text-base text-sm md:text-sm font font-semibold xl:pt-2 lg:pt-2 md:pt-2 pt-2">
                        Email
                      </p>
                      <p className="xl:text-base text-sm font md:text-sm pt-1">
                        Pruthviprojects2005@gmail.com
                      </p>
                    </div>

                    <div>
                      <p className="hometext xl:text-base text-sm md:text-sm font font-semibold xl:pt-2 lg:pt-2 md:pt-2 pt-2">
                        Phone
                      </p>
                      <p className="xl:text-base text-sm font md:text-sm pt-1">
                        +91 9900533422
                      </p>
                    </div>

                    <div>
                      <p className="hometext xl:text-base text-sm md:text-sm font font-semibold xl:pt-2 lg:pt-2 md:pt-2 pt-2 ">
                        Timings
                      </p>
                      <p className="xl:text-base text-sm font md:text-sm pt-1">
                        Monday - Sunday<br></br>
                        09:00 AM - 6:30 PM
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="xl:pb-16 pb-4 md:pb-4">
              <iframe
                className="rounded-md"
                width="100%"
                height={450}
                style={{ border: "0" }}
                loading="lazy"
                allowfullscreen
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.839804944995!2d77.64998451428848!3d12.911259990899648!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1472a986121f%3A0x1f276c83b107ea8e!2s645%2C%20Fourth%20Floor%2C%20Zeal%20square%2C%2012th%20cross%2C%2027th%20Main%20Rd%2C%20behind%20cafe%20coffee%20day%2C%201st%20Sector%2C%20HSR%20Layout%2C%20Bengaluru%2C%20Karnataka%20560102!5e0!3m2!1sen!2sin!4v1644811616887!5m2!1sen!2sin"
              ></iframe>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default Contactus;
