import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import assetkitchen from "assets/img/assetkitchen.jpg";
import assetbedroom from "assets/img/assetbedroom.jpg";
import assetliving from "assets/img/assetliving.jpg";
import assetbalcony from "assets/img/assetbalcony.jpg";



// Define CustomPrevArrow and CustomNextArrow components outside of SliderPage

const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
            onClick={onClick}
        >
            {/* custom left arrow icon or text */}
            {/* <span style={{ color: "blue", fontSize: "24px" }}>{"<"}</span> */}
            <span>{"<"}</span>
        </div>
    );
};

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
            onClick={onClick}
        >
            {/* custom right arrow icon or text with red color */}
            {/* <span style={{ color: "red", backgroundColor: "red", fontSize: "24px" }}>{">"}</span> */}
            <span>{">"}</span>
        </div>
    );
};

const SliderPage4 = () => {
    const settings = {
        dots: false,
        infinite: true,
        centerPadding: "10px",
        speed: 1300,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2.25,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.02,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
        // prevArrow: <CustomPrevArrow />,
        // nextArrow: <CustomNextArrow />,
    };

    return (
        <section
            className="bg-grey-50 overflow-x-auto px-4 xl:px-0 xl:pb-12 pb-2 "
            style={{ overflowX: "hidden" }}
        >
            <Slider {...settings}>

                <div className="xl:mx-2 md:mx-2  ">
                    <div class="xl:px-2 lg:px-2 md:px-2 sm:px-1 px-1">
                        <div class="image-container">
                            <img src="https://i.ibb.co/tCcJcL5/pruthviparadise-4.jpg" class="xl:h-400 lg:h-330 md:h-240 xl:w-520 lg:w-520 md:w-360 w-380 h-280"></img>

                        </div>
                    </div>
                </div>

                <div className="md:mx-2 xl:mx-2">
                    <div class="xl:px-2 lg:px-2 md:px-2 sm:px-1 px-1 ">
                        <div class="image-container">
                            <img src="https://i.ibb.co/ZTnx8nH/pruthviparadise-26.jpg" class="xl:h-400 lg:h-330 md:h-240 xl:w-520 lg:w-520 md:w-360 w-380 h-280"></img>
                        </div>
                    </div>
                </div>

                <div className="md:mx-2 xl:mx-2">
                    <div class="xl:px-2 lg:px-2 md:px-2 sm:px-1 px-1 ">
                        <div class="image-container">
                            <img src="https://i.ibb.co/jTtH242/pruthviparadise-25.jpg" class="xl:h-400 lg:h-330 md:h-240 xl:w-520 lg:w-520 md:w-360 w-380 h-280"></img>
                        </div>
                    </div>
                </div>

                <div className="md:mx-2 xl:mx-2">
                    <div class="xl:px-2 lg:px-2 md:px-2 sm:px-1 px-1 ">
                        <div class="image-container">
                            <img src="https://i.ibb.co/yQjP06L/pruthviparadise-19.jpg" class="xl:h-400 lg:h-330 md:h-240 xl:w-520 lg:w-520 md:w-360 w-380 h-280"></img>
                        </div>
                    </div>
                </div>

            </Slider>
        </section>
    );
};

export default SliderPage4;




