import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footers/Footer";
import ourcompany from "assets/img/ourcompany.jpg";
import ourmission from "assets/img/ourmission.jpg";
import teamline from "assets/img/teamline.jpg";
import xyzphoto from "assets/img/xyzphoto.jpg";
import xyphoto from "assets/img/xyphoto.jpg";
import mission2 from "assets/img/mission2.jpg";

const AboutUs = () => {
  return (
    <section>
      <IndexNavbar fixed />
      <div className="xl:pt-18 lg:pt-16 pt-4">
        <section
          style={{
            backgroundImage: `url("https://i.ibb.co/R0kT0vj/pruthvii.png")`,
            width: "100%",
            backgroundSize: "cover",
            // height: "200px",
            backgroundPosition: "center",
            backgroundColor: "black",
          }}
          className="xl:h-60 lg:h-220 md:h-240 h-48"
        >
          <div className="hometext text-white md:text-xl xl:text-3xl text-xl font-semibold text-center xl:pt-26 pt-24 lg:pt-28 md:pt-36">
            About Us
          </div>
        </section>

        {/* mission vision etc etc  */}

        <section className="container mx-auto overflow-hidden xl:px-16 px-4">
          <div className="grid xl:grid-cols-2 lg:grid-cols md:grid-cols-2 md:gap-4 xl:gap-8 xl:mb-12">
            <div className="text-black xl:mt-12 lg:mt-8 mt-4">
              <p className="hometext xl:text-2xl font text-xl font-semibold xl:pl-6">
                Our Company
              </p>
              <p className="xl:text-base lg:text-base md:text-sm font xl:mt-4 mt-2 xl:pl-6">
                Established in 2011, Pruthvi Projects focuses on constructing
                superior apartments, offering quality services at fair prices.
                We prioritize customer satisfaction by providing cost-effective
                designs tailored to their needs and addressing concerns
                promptly. Our reputation for timely delivery and high-quality
                construction is a testament to our commitment to excellence. We
                pride ourselves on strong client relationships, built through
                attentive listening and effective communication. At Pruthvi
                Projects, we continuously strive for improvement, building on
                our successes every day.
              </p>
            </div>

            <div className="xl:mt-12 lg:mt-8 mt-4 md:mt-16">
              <img src={ourcompany} className="xl:w-33  rounded-md"></img>
            </div>
          </div>

          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-8 lg:gap-4 md:gap-4 xl:mt-0 md:mt-8 md:gap-4 xl:mb-12">
            <div className="xl:block lg:block md:block hidden">
              <img
                src={mission2}
                className="rounded-md xl:mt-12 md:mt-6 xl:ml-6 xl:w-33 "
              ></img>
            </div>

            <div className="text-black xl:mt-16 mt-4 lg:mt-8 md:mt-0 ">
              <p className="xl:text-2xl font text-xl font-semibold hometext">Our Mission</p>
              <p className="xl:text-base lg:text-base md:text-sm xl:mt-4 font mt-2">
                We also take great pride in being a customer-focused
                organization. This “customer-first” philosophy is constantly
                translated into trailblazing services and innovative activities.
                There are many dreams that inspire Pruthvi Projects. Many
                avenues that await exploration. Many worlds that beckon us. It
                promises to be a glorious journey for Pruthvi Projects. Check
                out for various options for plots in Bangalore. Travel with us
                -- and together, let’s celebrate our milestones and our
                achievements .
              </p>
            </div>

            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src={mission2}
                className="rounded-md xl:mt-12 mt-4 xl:ml-6 xl:w-33 "
              ></img>
            </div>
          </div>

          <div className="grid xl:grid-cols-2 lg:grid-cols md:grid-cols-2 md:gap-4 md:mb-4 xl:gap-8 xl:mb-12">
            <div className="text-black xl:mt-24 mt-4 lg:mt-24">
              <p className="hometext xl:text-2xl text-xl font font-semibold xl:pl-6">
                Group Lineage
              </p>
              <p className="xl:text-base lg:text-base md:text-sm xl:mt-4 font mt-2 xl:pl-6">
                At Pruthvi Projects, we value your time and your money. We
                understand that not only should your index be a refuge from the
                storms of life but also should give you excellent return on
                investment (ROI). Delivery schedules are strictly adhered to,
                and “value-for-money” is not just another buzz term with us:
                it’s a business philosophy. We also take great pride in being a
                customer-focused organization. This “customer-first” philosophy
                is constantly translated into trailblazing services and
                innovative activities.
              </p>
            </div>
            <div className="xl:mb-0 mb-4">
              <img
                src={teamline}
                className="xl:w-33 xl:mt-12 lg:mt-12 mt-4 rounded-md"
              ></img>
            </div>
          </div>
        </section>

        {/* our team */}

        {/* <section className="bg-slate-200">
          <div className="container mx-auto overflow-hidden xl:px-16 px-4">
            <div className="xl:ml-8">
              <p className="hometext xl:text-3xl text-xl xl:mt-16 mt-4 font-semibold ">
                Meet Our Team
              </p>
              <p className="paratext xl:mb-8 xl:mt-4 mb-4">
                Meet our strong team partners
              </p>
              <div className="grid xl:grid-cols-4 md:grid-cols-4 grid-cols-2 gap-4 xl:gap-4 mb-4">
                <div className="relative xl:mb-16 ">
                  <img
                    src={xyzphoto}
                    alt="Our Company"
                    className="rounded-img"
                  ></img>
                  <div className="absolute bottom-0 left-0 w-full text-center text-white bg-gradient p-2">
                    <p className="xl:text-xl xl:pt-28 lg:pt-24 md:pt-12 pt-16 font-bold">
                      Grant Elliot
                    </p>
                    <p className="font-medium xl:text-base md:text-sm text-sm font">Funder</p>
                  </div>
                </div>

                <div className="relative xl:mb-16 ">
                  <img
                    src={xyphoto}
                    alt="Our Company"
                    className="rounded-img"
                  ></img>
                  <div className="absolute bottom-0 left-0 w-full text-center text-white bg-gradient p-2">
                    <p className="xl:text-xl xl:pt-28 lg:pt-24 md:pt-12 pt-16 font-bold">
                      Shivakumar kg
                    </p>
                    <p className="font-medium xl:text-base md:text-sm text-sm font">Head 0f Pruthvi</p>
                  </div>
                </div>

                <div className="relative xl:mb-16 ">
                  <img
                    src={xyzphoto}
                    alt="Our Company"
                    className="rounded-img"
                  ></img>
                  <div className="absolute bottom-0 left-0 w-full text-center text-white bg-gradient p-2">
                    <p className="xl:text-xl xl:pt-28 lg:pt-24 md:pt-12 pt-16 font-bold">
                      Gagan G Kumta
                    </p>
                    <p className="font-medium xl:text-base md:text-sm text-sm font">HR of Pruthvi</p>
                  </div>
                </div>

                <div className="relative xl:mb-16 ">
                  <img
                    src={xyphoto}
                    alt="Our Company"
                    className="rounded-img"
                  ></img>
                  <div className="absolute bottom-0 left-0 w-full text-center text-white bg-gradient p-2">
                    <p className="xl:text-xl xl:pt-28 lg:pt-24 md:pt-12 pt-16 font-bold">Lord Jesus</p>
                    <p className="font-medium xl:text-base md:text-sm text-sm  font">Funder,CEO </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <Footer />
      </div>
    </section>
  );
};

export default AboutUs;
