import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import WhatsApp from "WhatsApp";

// Import your Preloader component
import Preloader from "views/Preloader";

// Layouts
import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";
import Index from "views/Index.js";

// About us
import Aboutt from "about/aboutpage/Aboutt.js";
import AboutUs from "components/Pages/about/AboutUs.js";

// Contact us
import Contactus from "about/aboutpage/Contactus";
import Cloudslider from "views/Cloudslider";
import Service from "components/Homepage/Service";
import ProjectPage from "components/Pages/about/ProjectPage";
import ProjectHomepage from "components/Pages/ProjectHomepage";
import ProjectPage1 from "components/Pages/about/ProjectPage1";
import ProjectPage2 from "components/Pages/about/ProjectPage2";
import ProjectPage3 from "components/Pages/about/ProjectPage3";
import ProjectSlider from "views/ProjectSlider";

const App = () => {
  // Define state to track loading status
  const [loading, setLoading] = useState(true);

  // Simulate loading with useEffect
  useEffect(() => {
    // Simulating async data loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the time as needed

    // Clean up timer
    return () => clearTimeout(timer);
  }, []);

  return (
    <BrowserRouter>
     <WhatsApp />
      {/* Conditionally render Preloader or content based on loading state */}
      {loading ? (
        <Preloader />
      ) : (
        <Switch>
          {/* add routes with layouts */}
          {/* <Route path="/admin" component={Admin} />
          <Route path="/auth" component={Auth} /> */}
          {/* <Route path="/aboutt" component={Aboutt} /> */}
          {/* add routes without layouts */}
          {/* <Route path="/landing" exact component={Landing} />
          <Route path="/profile" exact component={Profile} /> */}

          {/* Homepage */}
          {/* Aboutus page */}
          <Route path="/about-us" exact component={Aboutt} />
          <Route path="/index" exact component={Index} />

          {/* services */}
          <Route path="/service" exact component={Service} />

          {/* About us */}
          <Route path="/aboutt-us" exact component={AboutUs} />

          {/* Project Page */}
          <Route path="/project-homepage" exact component={ProjectSlider} />
          <Route path="/project-page" exact component={ProjectPage} />
          <Route path="/project-page1" exact component={ProjectPage1} />
          <Route path="/project-page2" exact component={ProjectPage2} />
          <Route path="/project-page3" exact component={ProjectPage3} />

          {/* Contact us */}
          <Route path="/contact" exact component={Contactus} />
          <Route path="/" exact component={Index} />

          {/* Inside dropdown content */}
          {/* add redirect for first page */}
          <Redirect from="*" to="/" />
        </Switch>
      )}
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
