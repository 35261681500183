import React, { useState, useRef } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar";
import Footer from "components/Footers/Footer";
import mapmarker from "assets/img/mapmarker.png";
import sqft from "assets/img/icons/sqft.png";
import calendar from "assets/img/icons/calendar.png";
import car from "assets/img/icons/car.png";
import bed from "assets/img/icons/bed.png";
import shower from "assets/img/icons/shower.png";
import checkmark from "assets/img/icons/checkmark.png";
import floor from "assets/img/icons/floor.png";
import firstfloor from "assets/img/fisrtfloor.jpg";
import image1 from "assets/img/image1.jpg";
import image2 from "assets/img/image2.jpg";
import image3 from "assets/img/image3.jpg";
import image4 from "assets/img/image4.jpg";
import image5 from "assets/img/image5.jpg";
import whatsapp from "assets/img/icons/whatsapp.png";
import SliderPage2 from "views/SliderPage2";
import boat from "assets/img/boat.jpg";
import boat3 from "assets/img/boat3.jpg";
import enquiry1 from "assets/img/enquiry1.jpg";
import asset1 from "assets/img/asset1.jpg";
import pool from "assets/img/pool.jpg";
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


toast.configure({
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    maxWidth: '800px',
})



const ProjectPage = () => {



    const settings = {
        dots: false,
        infinite: true,
        centerPadding: "10px",
        speed: 1300,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],

    };







    const [showImage, setShowImage] = useState(false);

    const handleClick = () => {
        setShowImage(!showImage);
    };

    const [showImage1, setShowImage1] = useState(false);

    const handleClickk = () => {
        setShowImage1(!showImage1);
    };

    // next and previous image

    const [currentImage, setCurrentImage] = useState(0);

    const nextImage = () => {
        setCurrentImage((prevImage) => (prevImage === 4 ? 0 : prevImage + 1));
    };

    const prevImage = () => {
        setCurrentImage((prevImage) => (prevImage === 0 ? 4 : prevImage - 1));
    };


    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [project, setProject] = useState('');

    const form = useRef();
    const sendEmail = (e) => {

        emailjs.sendForm('service_re0yn5q', 'template_xdi9r9r', form.current, 'klAgwhrXKwJEHK3q7')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        toast('Thank you for contacting Pruthvi Projects. We have received your enquiry and will respond to you as soon as possible. ')
        e.preventDefault(); // 👈️ prevent page refresh

        // 👇️ clear all input values in the form
        setFullName('');
        setEmail('');
        setPhoneNumber('');
        setMessage('');
        setProject('');

    };

    return (
        <section>
            <IndexNavbar />
            <div className="xl:mt-16 lg:mt-22 md:mt-20 sm:mt-16 mt-16">
                {/* <div className="">
                    <div className="container mx-auto relative text-blue xl:px-16 px-4 xl:py-4 lg:py-4 sm:py-4 py-1">
                        <a href="index" className="link text-ivory-black">
                            Home {"  "} 
                        </a>
                        {"  "}― {"  "}
                        <a href="/project-homepage" className="link">
                            Project {"  "}
                        </a>
                        {"  "} ― {"  "}
                        Project Description
                    </div>
                </div> */}
            </div>

            <SliderPage2 />

            {/* Desktop tab and tab landscape view */}

            <div className="xl:block lg:block md:block hidden">
                <div className="container xl:px-16 lg:px-12 md:px-12 sm:px-4 px-4 overflow-hidden mx-auto">
                    <div className="relative">
                        <div className="align-items justify-center xl:mt-12 lg:mt-12 md:mt-12 mt-8 xl:mb-28 lg:mb-24 md:mb-24 sm:mb-8 mb-8 relative">
                            <img src="https://i.ibb.co/KWJJvvf/pruthvihome.jpg" className="rounded-md xl:ml-4 lg:ml-4 md:ml-2 ml-2 xl:w-650 lg:w-560 md:w-430 w-250 xl:h-360 lg:h-340 md:h-280 h-180" />
                            <div class="rounded-md grey-box xl:mt-16 lg:mt-10 md:mt-8 mt-0 text-black xl:w-530 lg:w-380 md:w-330 w-100 xl:h-355 lg:h-340 md:h-280 h-150">
                                <div className="xl:mt-24 lg:mt-20 md:mt-16 mt-2 xl:ml-20 lg:ml-16 md:ml-12 ml-2 xl:pr-0 lg:pr-0 md:pr-4 pr-0">
                                    <p className="hometext font-semibold xl:text-2xl lg:text-2xl md:text-xl text-xs xl:mt-4 pl-0 xl:pl-0 md:pl-0 lg:pl-0 lg:mt-2 md:mt-2 mt-2">
                                        Where Life Begins &<br></br>
                                        Trade Fairs
                                    </p>
                                    <p className="paratext xl:mt-4 lg:mt-6 md:mt-4 mt-4 xl:text-lg lg:text-base md:text-sm pl-0 xl:pl-0 md:pl-0 lg:pl-0 xl:pr-0 lg:pr-4 md:pr-0 pr-0 text-xs xl:mb-8 lg:mb-2 md:mb-2 mb-4">
                                        Our endeavor is to create a unique world for you, one that transports you to a realm of fine living and indulgence.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile view */}
            <div className="xl:hidden lg:hidden md:hidden block">
                <div className="container xl:px-16 lg:px-12 md:px-12 sm:px-2 px-4 overflow-hidden mx-auto">
                    <div>
                        <p className="hometext font-semibold xl:text-2xl lg:text-2xl md:text-2xl text-xl xl:mt-4 lg:mt-4 md:mt-4 mt-4">
                            Where Life Begins &<br></br>
                            Trade Fairs
                        </p>
                        <p className="paratext xl:mt-4 lg:mt-6 md:mt-4 mt-2 xl:text-lg text-base xl:mb-8 lg:mb-6 md:mb-4 mb-0">
                            Our endeavor is to create a unique world for you, one that transports you to a realm of fine living and indulgence.
                        </p>
                    </div>
                    <div><img src="https://i.ibb.co/KWJJvvf/pruthvihome.jpg" className="rounded-md mt-4" /></div>
                </div>

            </div>

            {/* desktop view */}

            <div className="xl:block lg:block md:block hidden">
                <div className="bg-grey-100 mt-4 xl:mt-0 lg:mt-0 md:mt-12">
                    <div className="container xl:px-16 lg:px-12 md:px-12 px-4 overflow-hidden mx-auto ">
                        <div className="grid grid-cols-3 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-2 gap-2 xl:mt-16 lg:mt-12 md:mt-12 mt-4">

                            <div className="hometext justify-left uppercase font-bold xl:text-3xl lg:text-xl md:text-xl text-sm
                        xl:ml-4 lg:ml-4 md:ml-4 ml-0">
                                OUR STRATEGIC APPROACH
                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-xl text-sm">
                                <b>Advantages</b>

                                <p className="xl:text-base lg:text-base md:text-sm sm:text-base text-xs xl:mt-4 lg:mt-4 md:mt-2 mt-2">
                                    Our luxury Apartments project in the flourishing environs of Jigani Bangalore, we are poised
                                    to deliver on this promise. You often dream of a place close to Jigani Bangalore city,
                                    yet calm. The spacious Apartments in Jigani Bangalore are well equipped to embrace all
                                    your dreams. A luxurious Apartments in Jigani Bangalore, PRUTHVI HOMES is poised with mazing architectural efforts.
                                </p>
                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-xl text-sm">
                                <b>Best Practices</b>
                                <p className="xl:text-base lg:text-base md:text-sm sm:text-base text-xs xl:mt-4 lg:mt-4 md:mt-2 mt-2">
                                    As Pruthvi, we follow best practices that include concrete tests for strength assessment and
                                    the use of suitable vibrators for leveling and creating a smooth surface.
                                    To ensure accuracy, we employ GPS techniques to ensure verticality within limits.
                                    We strictly adhere to GFC drawings and conduct regular audits to maintain quality throughout the construction process.
                                </p>
                            </div>

                        </div>

                        <div className="grid grid-cols-3 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-2 gap-2 xl:mt-8 lg:mt-8 md:mt-6 mt-4 xl:mb-16 lg:mb-14 md:mb-12 mb-4">
                            <div>

                            </div>
                            <div className="hometext xl:text-2xl lg:text-2xl md:text-xl text-sm">
                                <b>Buying Guide</b>
                                <p className="xl:text-base lg:text-base md:text-sm sm:text-base text-xs xl:mt-4 lg:mt-4 md:mt-2 mt-2">
                                    At Pruthvi, we offer a transparent buying guide, supported by in-depth market research and professional
                                    business practices. Our commitment to delivering high-quality products is evident in our comprehensive
                                    approach that covers design, landscaping, and infrastructure. Our focus on community living sets us apart in the market.
                                </p>
                            </div>


                            <div className="hometext xl:text-2xl lg:text-2xl md:text-xl text-sm">
                                <b>Referral program</b>
                                <p className="xl:text-base lg:text-base md:text-sm sm:text-base text-xs xl:mt-4 lg:mt-4 md:mt-2 mt-2">
                                    With Pruthvi's referral program, you can trust our concrete tests for strength assessment and
                                    the use of suitable vibrators for leveling and creating a smooth surface.
                                    We ensure verticality within limits using GPS techniques, and our strict adherence to GFC drawings is
                                    backed by regular audits to maintain quality at every step of the construction process.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* mobile view */}

            <div className="xl:hidden lg:hidden md:hidden block">
                <div className="bg-grey-100 mt-4">
                    <div className="container xl:px-16 lg:px-12 md:px-12 px-4 overflow-hidden mx-auto ">
                        <div className="grid grid-rows-3 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-2 gap-2 xl:mt-16 lg:mt-14 md:mt-12 mt-4">

                            <div className="hometext justify-left uppercase font-bold xl:text-xl lg:text-xl md:text-xl text-xl xl:ml-4 lg:ml-4 md:ml-4 ml-0">
                                OUR STRATEGIC APPROACH
                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-2xl text-lg mt-4">
                                <b>Advantages</b>

                                <p className="xl:text-base lg:text-base md:text-base sm:text-base text-base xl:mt-4 lg:mt-4 md:mt-2 mt-2">
                                    Our luxury Apartments project in the flourishing environs of Jigani Bangalore, we are poised
                                    to deliver on this promise. You often dream of a place close to Jigani Bangalore city,
                                    yet calm. The spacious Apartments in Jigani Bangalore are well equipped to embrace all
                                    your dreams. A luxurious Apartments in Jigani Bangalore, PRUTHVI HOMES is poised with mazing architectural efforts.

                                </p>
                            </div>

                            <div className="hometext xl:text-2xl lg:text-2xl md:text-2xl text-lg mt-4">
                                <b>Best Practices</b>
                                <p className="xl:text-base lg:text-base md:text-base sm:text-base text-base xl:mt-4 lg:mt-4 md:mt-2 mt-2">
                                    As Pruthvi, we follow best practices that include concrete tests for strength assessment and
                                    the use of suitable vibrators for leveling and creating a smooth surface.
                                    To ensure accuracy, we employ GPS techniques to ensure verticality within limits.
                                    We strictly adhere to GFC drawings and conduct regular audits to maintain quality throughout the construction process.
                                </p>
                            </div>

                        </div>

                        <div className="grid grid-rows-3 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-2 gap-2 xl:mt-8 lg:mt-8 md:mt-6 mt-4 xl:mb-16 lg:mb-14 md:mb-12 mb-4">
                            <div>

                            </div>
                            <div className="hometext xl:text-2xl lg:text-2xl md:text-2xl text-lg">
                                <b>Buying Guide</b>
                                <p className="xl:text-base lg:text-base md:text-base sm:text-base text-base xl:mt-4 lg:mt-4 md:mt-2 mt-2">
                                    At Pruthvi, we offer a transparent buying guide, supported by in-depth market research and professional
                                    business practices. Our commitment to delivering high-quality products is evident in our comprehensive
                                    approach that covers design, landscaping, and infrastructure. Our focus on community living sets us apart in the market.
                                </p>
                            </div>


                            <div className="hometext xl:text-2xl lg:text-2xl md:text-2xl text-lg mt-4">
                                <b>Referral program</b>
                                <p className="xl:text-base lg:text-base md:text-base sm:text-base text-base xl:mt-4 lg:mt-4 md:mt-2 mt-2">
                                    With Pruthvi's referral program, you can trust our concrete tests for strength assessment and
                                    the use of suitable vibrators for leveling and creating a smooth surface.
                                    We ensure verticality within limits using GPS techniques, and our strict adherence to GFC drawings is
                                    backed by regular audits to maintain quality at every step of the construction process.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            {/* Desktop tab and tab landscape view */}

            <div className="xl:block lg:block md:block hidden">
                <div className="container xl:px-16 lg:px-12 md:px-12 sm:px-4 px-4 overflow-hidden mx-auto">
                    <div className="relative">
                        <div className="align-items justify-center xl:mt-12 lg:mt-12 md:mt-12 mt-8 xl:mb-28 lg:mb-24 md:mb-24 sm:mb-8 mb-8 relative">
                            <img src={pool} className="rounded-md xl:ml-4 lg:ml-4 md:ml-2 ml-2 xl:w-650 lg:w-560 md:w-430 w-250 xl:h-360 lg:h-340 md:h-280 h-180" />
                            <div class="rounded-md grey-box xl:mt-16 lg:mt-10 md:mt-8 mt-0 text-black xl:w-530 lg:w-380 md:w-330 w-100 xl:h-355 lg:h-340 md:h-280 h-150">
                                <div className="xl:mt-12 lg:mt-20 md:mt-16 mt-2 xl:ml-20 lg:ml-16 md:ml-12 ml-2 xl:pr-0 lg:pr-0 md:pr-4 pr-0">
                                    <p className="hometext font-semibold xl:text-2xl lg:text-2xl md:text-xl text-base xl:mt-4 lg:mt-2 md:mt-2 mt-4">
                                        Pruthvi Conventions & <br></br>
                                        Trade Fairs</p>
                                    <p className="paratext xl:mt-2 lg:mt-6 md:mt-4 mt-4 xl:text-base lg:text-base md:text-sm xl:mb-8 lg:mb-6 md:mb-4 mb-4">
                                        It has a close proximity to all the public places; yet calm in its own nviron. The sheer verdant atmosphere
                                        boosted by the unique landscaping created by renowned designers,upled with the cutting edge lifestyle
                                        choices and the breathtaking aesthetics, ensure that you get the best of both worlds. These luxurious Apartments in Bangalore have more value than you could ever imagine.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile view */}


            <div className="xl:hidden lg:hidden md:hidden block">
                <div className="container xl:px-16 lg:px-12 md:px-12 sm:px-2 px-4 overflow-hidden mx-auto">
                    <div><img src={pool} className="rounded-md mt-2" /></div>
                    <div>
                        <p className="hometext font-semibold xl:text-2xl lg:text-2xl md:text-2xl text-xl xl:mt-2 lg:mt-2 md:mt-2 mt-4">
                            Pruthvi Conventions & <br></br>
                            Trade Fairs</p>
                        <p className="paratext xl:mt-4 lg:mt-6 md:mt-4 mt-2 xl:text-lg xl:mb-8 lg:mb-6 md:mb-4 mb-4">
                            It has a close proximity to all the public places; yet calm in its own nviron. The sheer verdant atmosphere
                            boosted by the unique landscaping created by renowned designers,upled with the cutting edge lifestyle
                            choices and the breathtaking aesthetics, ensure that you get the best of both worlds. These luxurious Apartments in Bangalore have more value than you could ever imagine.
                        </p>
                    </div>
                </div>
            </div>





            <section className="container mx-auto">
                <div className="px-24">
                    <Slider {...settings}>
                        <div className="px-4">
                            <img src="https://i.ibb.co/CQ8NcQh/p-1.jpg" alt="" className="" />
                        </div>
                        <div className="px-4">
                            <img src="https://i.ibb.co/wrbMQDD/p-2.jpg" alt="" />
                        </div>
                        <div className="px-4">
                            <img src="https://i.ibb.co/wQst7kn/p-3.jpg" alt="" />
                        </div>
                        <div className="px-4">
                            <img src="https://i.ibb.co/Kbz0qB0/p-4.jpg" alt="" />
                        </div>
                        <div className="px-4">
                            <img src="https://i.ibb.co/gjQn3BF/p-5.jpg" alt="" />
                        </div>

                    </Slider>
                </div>
            </section>


            {/* desktop view  */}

            <div className="xl:block lg:block md:block hidden">
                <div className="bg-grey-50 xl:pb-0 xl:mt-0 lg:mt-0 md:mt-12 lg:pb-12 md:pb-16">
                    <div className="container xl:px-16 lg:px-12 md:px-12 px-4 overflow-hidden mx-auto">
                        <div className="relative grid grid-cols-2">

                            <div className="hometext xl:text-4xl lg:text-3xl md:text-3xl text-base xl:mt-8 lg:mt-8 md:mt-8 mt-6 md:mb-12">


                                <div>
                                    <div className="xl:mt-8 lg:mt-4 md:mt-4 mt-4 xl:mb-12 xl:gap-4 lg:gap-4 md:gap-4 lg:grid-cols-2 md:grid-cols-2 grid-cols-1">
                                        <div className="bg-white rounded-md text-black xl:p-5 lg:p-5 md:p-4">
                                            <form ref={form} onSubmit={sendEmail} className=" ">
                                                <div className="xl:text-2xl lg:text-xl md:text-lg font font-semibold">
                                                    Real Estate Enquiry Form <br></br>
                                                    <p className="xl:text-lg lg:text-sm md:text-sm text-sm font-normal font xl:mt-2">
                                                        Contact us today if you’d like to know more about how we help buy, or rent your home
                                                    </p>
                                                </div>

                                                <div className="grid grid-rows-4 xl:gap-4 md:gap-2 sxl:mt-4 md:mt-4 lg:gap-2 lg:mt-2">
                                                    <input
                                                        type="text"
                                                        className="pl-4 rounded font border-light-grey h-10 md:text-xs xl:text-base lg:text-base text-xs"
                                                        placeholder="Enter Name"
                                                        name="fullName"
                                                        maxlength="35" onChange={event => setFullName(event.target.value)}
                                                        value={fullName} required
                                                    />
                                                    <input
                                                        className="pl-4 rounded font border-light-grey h-10 md:text-xs xl:text-base lg:text-base text-xs"
                                                        type="tel"
                                                        placeholder="Enter Phone Number"
                                                        name="phoneNumber"
                                                        value={phoneNumber}
                                                        onChange={event => setPhoneNumber(event.target.value)} required
                                                    ></input>
                                                    <input
                                                        className="pl-4 rounded font border-light-grey h-10 md:text-xs xl:text-base lg:text-base text-xs"
                                                        name="email"
                                                        type="email"
                                                        placeholder="Enter Email"
                                                        value={email}
                                                        onChange={event => setEmail(event.target.value)} required
                                                    ></input>
                                                </div>

                                                <div className="grid lg:grid-rows-1 mt-2 lg:mt-2">
                                                    <select
                                                        id="EnquiryType"
                                                        className="xl:mt-2 font rounded-md border-light-grey w-full h-10 px-4"
                                                        value={project} name="project"
                                                        onChange={event => setProject(event.target.value)} required // Make project selection required
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Pruthvi Home">Pruthvi Home</option>
                                                        <option value="Pruthvi Home">Pruthvi Asset</option>
                                                        <option value="Pruthvi Paradise">Pruthvi Paradise</option>
                                                        <option value="Pruthvi Prime">Pruthvi Prime</option>
                                                    </select>
                                                </div>

                                                <div className="grid grid-rows-1 xl:gap-4 mt-4">
                                                    <textarea
                                                        className="pl-4 rounded font border-light-grey h-20 md:text-xs xl:text-base lg:text-base text-xs"
                                                        placeholder="Hello, I am interested in [Design apartment]"
                                                        value={message}
                                                        name="message"
                                                        onChange={event => setMessage(event.target.value)} required></textarea>

                                                </div>

                                                {/* <div className="xl:mt-4">
                        <input
                          type="checkbox"
                          className="xl:ml-2 form-checkbox h-3 w-3 text-blue-950 "
                        />
                        <span className="xl:ml-4 text-blue-950 font">
                          By submitting this form I agree to Terms of Use
                        </span>
                      </div> */}

                                                <div className="grid grid-cols-2 xl:gap-2 lg:gap-2 md:gap-2 xl:mt-8 md:mt-2 lg:mt-2">
                                                    <button className="font gradient2 rounded border text-white lg:text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2"
                                                        type="submit"
                                                        value="submit">
                                                        Send Message
                                                    </button>
                                                    <a href="/PruthviHomes.pdf" download  >
                                                        <div className="text-center font gradient2 rounded border text-white lg:text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2">
                                                            Download Brochure
                                                        </div>
                                                    </a>

                                                </div>
                                            </form>
                                            <div className="grid grid-cols-2 gap-2 items-center">
                                                <a href="https://wa.me/+919900533422" className="pt-1">
                                                    <button className="w-12/12 rounded gradient2 border text-white lg:text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2">
                                                        <div className="flex justify-center items-center">
                                                            <img src={whatsapp} className="w-4 h-4  mr-2" />
                                                            Whats App
                                                        </div>
                                                    </button>
                                                </a>
                                                <a href="tel:+919900533422" className="-mt-1">
                                                    <button className="w-12/12 font gradient2 rounded border text-white  lg:text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2">
                                                        Call
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="relative grid grid-cols-1">

                                <div className="xl:mt-8 lg:mt-4 md:mt-44 mt-4 xl:ml-8 lg:ml-4 md:ml-4 ml-2">
                                    <img src="https://i.ibb.co/KWJJvvf/pruthvihome.jpg" className="rounded-md md:mt-18 xl:h-500"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* mobile view  */}

            <div className="xl:hidden lg:hidden md:hidden block">
                <div className="bg-grey-100">
                    <div className="container xl:px-16 lg:px-12 md:px-12 px-4 overflow-hidden mx-auto">
                        <div className="relative grid grid-rows-2">

                            <div className="hometext xl:text-4xl lg:text-3xl md:text-2xl text-2xl
                        xl:mt-8 lg:mt-8 md:mt-8 mt-6">


                                <div>
                                    <div className="xl:mt-8 lg:mt-4 md:mt-4 mt-4 xl:mb-12 xl:gap-4 lg:gap-4 md:gap-4 lg:grid-cols-2 md:grid-cols-2 grid-cols-1">
                                        <div className="bg-white rounded-md text-black xl:p-5 lg:p-5 p-3">
                                            <form ref={form} onSubmit={sendEmail} className=" ">
                                                <div className="xl:text-2xl lg:text-xl md:text-lg font font-semibold">
                                                    Real Estate Enquiry Form <br></br>
                                                    <p className="xl:text-lg lg:text-sm md:text-sm text-sm font-normal font xl:mt-2">
                                                        Contact us today if you’d like to know more about how we help buy, or rent your home
                                                    </p>
                                                </div>

                                                <div className="grid grid-rows-4 xl:gap-4 md:gap-2 sxl:mt-4 md:mt-4 lg:gap-2 lg:mt-2">
                                                    <input
                                                        type="text"
                                                        className="pl-4 rounded font border-light-grey h-10 md:text-xs my-2 xl:text-base lg:text-base text-xs"
                                                        placeholder="Enter Name"
                                                        name="fullName"
                                                        maxlength="35" onChange={event => setFullName(event.target.value)}
                                                        value={fullName} required
                                                    />
                                                    <input
                                                        className="pl-4 rounded font border-light-grey h-10 md:text-xs my-2 xl:text-base lg:text-base text-xs"
                                                        type="tel"
                                                        placeholder="Enter Phone Number"
                                                        name="phoneNumber"
                                                        value={phoneNumber}
                                                        onChange={event => setPhoneNumber(event.target.value)} required
                                                    ></input>
                                                    <input
                                                        className="pl-4 rounded font border-light-grey h-10 md:text-xs my-2 xl:text-base lg:text-base text-xs"
                                                        name="email"
                                                        type="email"
                                                        placeholder="Enter Email"
                                                        value={email}
                                                        onChange={event => setEmail(event.target.value)} required
                                                    ></input>
                                                </div>

                                                <div className="grid lg:grid-rows-1 mt-2 lg:mt-2">
                                                    <select
                                                        id="EnquiryType"
                                                        className="xl:mt-2 font rounded-md border-light-grey w-full h-10 px-4"
                                                        value={project} name="project"
                                                        onChange={event => setProject(event.target.value)} required // Make project selection required
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Pruthvi Home">Pruthvi Home</option>
                                                        <option value="Pruthvi Home">Pruthvi Asset</option>
                                                        <option value="Pruthvi Paradise">Pruthvi Paradise</option>
                                                        <option value="Pruthvi Prime">Pruthvi Prime</option>
                                                    </select>
                                                </div>

                                                <div className="grid grid-rows-1 xl:gap-4 mt-4">
                                                    <textarea
                                                        className="pl-4 rounded font border-light-grey h-20 md:text-xs xl:text-base lg:text-base text-xs"
                                                        placeholder="Hello, I am interested in [Design apartment]"
                                                        value={message}
                                                        name="message"
                                                        onChange={event => setMessage(event.target.value)} required></textarea>

                                                </div>

                                                {/* <div className="xl:mt-4">
                        <input
                          type="checkbox"
                          className="xl:ml-2 form-checkbox h-3 w-3 text-blue-950 "
                        />
                        <span className="xl:ml-4 text-blue-950 font">
                          By submitting this form I agree to Terms of Use
                        </span>
                      </div> */}

                                                <div className="grid grid-cols-2 xl:gap-4 lg:gap-2 md:gap-2 mt-8 md:mt-2 lg:mt-2">
                                                    <button className="font gradient2 rounded border text-white text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2"
                                                        type="submit"
                                                        value="submit">
                                                        Send Message
                                                    </button>
                                                    <a href="/PruthviHomes.pdf" download  >
                                                        <div className="w-12/12 font gradient2 rounded border text-white text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2 text-center">
                                                            Download Brochure
                                                        </div>
                                                    </a>

                                                </div>
                                                
                                            </form>

                                            <div className="grid grid-cols-2 gap-2 items-center xl:mt-4 lg:mt-2 md:mt-1 mt-2">
                                                <a href="https://wa.me/+919900533422" className="">
                                                    <button className="w-12/12 rounded gradient2 border text-white text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2">
                                                        <div className="flex justify-center items-center">
                                                            <img src={whatsapp} className="w-4 h-4  mr-2" />
                                                            Whats App
                                                        </div>
                                                    </button>
                                                </a>
                                                <a href="tel:+919900533422" className="-mt-1">
                                                    <button className="w-12/12 font gradient2 rounded border text-white text-sm md:text-sm xl:py-2 lg:py-2 md:py-1 py-2">
                                                        Call
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="relative grid grid-cols-1">

                                <div className="xl:mt-26 lg:mt-6 md:mt-6 mt-4 xl:ml-8 lg:ml-4 md:ml-4 ml-0 mb-4">
                                    <img src="https://i.ibb.co/KWJJvvf/pruthvihome.jpg" className="rounded-md"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section >
    );
};

export default ProjectPage;
