/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import home from "assets/img/icons/home.png";
import phonecallnew from "assets/img/icons/phonecallnew.png";
import { HashLink } from "react-router-hash-link";
import pruthvilogo from "assets/img/images/pruthvilogo.png";
import $ from "jquery";

export default function IndexNavbar(props) {
  const handleTalkToSalesClick = () => {
    window.location.href = "tel:+919176793939";
  };

  $(document).ready(function () {
    var scrollTop = 0;
    $(window).scroll(function () {
      scrollTop = $(window).scrollTop();
      $(".counter").html(scrollTop);

      if (scrollTop >= 100) {
        $("#global-nav").addClass("scrolled-nav");
      } else if (scrollTop < 100) {
        $("#global-nav").removeClass("scrolled-nav");
      }
    });
  });

  const [navbarOpen, setNavbarOpen] = React.useState(false);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section>
      <nav id="global-nav" className="nav">
        <div className="">
          <div className="z-50 top-0 w-full bg-white ">
            <div className="container mx-auto xl:px-16 px-4 overflow-hidden">
              <div
                className={
                  "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none py-3 xl:pt-1 xl:py-0 " +
                  (navbarOpen ? " block h-screen xl:h-auto" : " hidden ")
                }
                id="example-navbar-warning"
              >
                <div className="px-0" id="navbarContainer">
                  <div className="w-full relative flex justify-between lg:w-auto lg:static lg:justify-start items-center">
                    <div className="pr-4 xl:ml-0 pull-left">
                      <span>
                        <a href="/">
                          <img
                            src="https://i.ibb.co/yd7G9dZ/pruthivi-new-logo.jpg"
                            alt=""
                            className={`w-32 xl:pt-0 lg:pt-0 lg:mt-0 mt-0 xl:mt-0 cursor-pointer pr-12 lg:pr-0 ${
                              isScrolled ? "" : "hidden"
                            }`}
                          />
                        </a>
                      </span>
                    </div>
                    <button
                      className="cursor-pointer text-xs leading-none block lg:hidden outline-none focus:outline-none"
                      type="button"
                      onClick={() => setNavbarOpen(!navbarOpen)}
                    >
                      ✖
                    </button>
                  </div>
                </div>


                <ul className="flex flex-col lg:flex-row list-none ml-auto justify-center pull-right">
                  <div className="xl:block lg:block md:hidden hidden">
                    <a href="/">
                      <p className=" paratext font-bold uppercase xl:text-base lg:text-base cursor-pointer md:pl-0 pl-0 xl:pl-2 xl:pr-4  md:pb-3 pb-0 xl:pb-0 lg:pb-0  lg:pl-0 md:pt-2 pt-4 xl:pt-1 lg:pt-0 lg:px-2 xl:pr-0 pr-r md:pr-0 md:pl-4 text-lg hover:text-red-800boldhome text-blueGray-700">
                        Home{" "}
                      </p>
                    </a>
                  </div>

                  <div className="xl:block lg:block md:hidden hidden">
                    <a href="/project-homepage">
                      <p className="paratext font-bold uppercase xl:text-base lg:text-base cursor-pointer md:pl-0 pl-0 xl:pl-2 xl:pr-4  md:pb-3 pb-0 xl:pb-0 lg:pb-0  lg:pl-0 md:pt-2 pt-4 xl:pt-1 lg:pt-0 lg:px-2 xl:pr-0 pr-r md:pr-0  hover:text-red-800boldhome text-lg  text-blueGray-700">
                        Projects{" "}
                      </p>
                    </a>
                  </div>

                  <div className="xl:block lg:block md:hidden hidden">
                    <a href="/aboutt-us">
                      <p className="paratext font-bold uppercase xl:text-base lg:text-base cursor-pointer md:pl-0 pl-0 xl:pl-2 xl:pr-4  md:pb-3 pb-0 xl:pb-0 lg:pb-0  lg:pl-0 md:pt-2 pt-4 xl:pt-1 lg:pt-0 lg:px-2 xl:pr-0 pr-r md:pr-0  hover:text-red-800boldhome text-lg text-blueGray-700">
                        About Us{" "}
                      </p>
                    </a>
                  </div>

                  <div className="xl:block lg:block md:hidden hidden">
                    <a href="/contact">
                      <p className="paratext font-bold uppercase xl:text-base lg:text-base cursor-pointer md:pl-0 pl-0 xl:pl-2 xl:pr-6  md:pb-3 pb-0 xl:pb-0 lg:pb-0  lg:pl-0 md:pt-2 pt-4 xl:pt-1 lg:pt-0 lg:px-2 lg:mr-4 xl:pr-0 pr-r md:pr-0  hover:text-red-800boldhome text-lg text-blueGray-700">
                        Contact Us{" "}
                      </p>
                    </a>
                  </div>


                  <li className="xl:hidden lg:hidden md:block block  pt-6 items-center border-b pb-4">
                    <p class="paratext   text-grey-600 text-sm  ">
                      Pruthvi Projects focuses on constructing superior apartments, offering quality services at fair prices.
                    </p>
                  </li>
                  <li className="xl:hidden lg:hidden md:block block w-full pt-8">
                    <a className=" " href="/">
                      <p class="paratext uppercase  text-grey-600 text-sm  ">
                        Home
                      </p>

                    </a>
                  </li>

                  <li className="xl:hidden lg:hidden md:block block w-full pt-6">
                    <a className="" href="/project-homepage">
                      <p class="paratext uppercase  text-grey-600 text-sm  ">
                        Projects
                      </p>

                    </a>
                  </li>

                  <li className="xl:hidden lg:hidden md:block block w-full pt-6">
                    <a className=" " href="/aboutt-us">
                      <p class="paratext uppercase  text-grey-600 text-sm  w-full ">
                        About Us
                      </p>
                    </a>
                  </li>

                  <li className="xl:hidden lg:hidden md:block block w-full pt-6">
                    <a className=" " href="/contact">
                      <p class="paratext uppercase  text-grey-600 text-sm  w-full ">
                        Contact Us
                      </p>
                    </a>
                  </li>
                </ul>

                <HashLink smooth to="/contact#enquiry">
                  <button class="font uppercase text-xs xl:text-base lg:text-base xl:px-4 lg:px-4 px-6 xl:py-1  lg:py-1 py-2 gradient2 text-white xl:mt-0 lg:mt-0 mt-6 rounded">
                    Enquiry
                  </button>
                </HashLink>

                {/* <a href="/other-page#enquiry">
              <button className="lg:buttonWithGradient xl:buttonWithGradient md:buttonWithGradient buttonWithGradient font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-2 md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white md:ml-6 ml-2 xl:ml-0 lg:-ml-3">
                Enquiry
              </button>
            </a> */}

                <div className="xl:hidden lg:hidden md:block block">
                  <div className="mt-12 pt-8 border-t xl:pl-4 md:pl-4 pb-32 pl-0">
                    <a href="">
                      <button
                        className="gradient2 text-white shadow-lg font-normal  h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                        type="button"
                        href=""
                      >
                        <i className="fab fa-facebook"></i>
                      </button>
                    </a>

                    <a href="">
                      <button
                        className="gradient2 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                        type="button"
                        href="twitter"
                      >
                        <i className="">𝕏</i>
                      </button>
                    </a>



                    <a href="">
                      <button
                        className="gradient2 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                        type="button"
                        href="youtube"
                      >
                        <i className="fab fa-youtube"></i>
                      </button>
                    </a>

                    <a href="">
                      <button
                        className="gradient2 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                        type="button"
                        href="youtube"
                      >
                        <i className="fab fa-linkedin"></i>
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="xl:hidden lg:hidden md:block sm:block block md:py-4 py-4">
                <div className="px-0">
                  <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                    <div className={`px-0 ${navbarOpen ? "hidden" : ""}`}>
                      <span>
                        <a href="index">
                          <img
                            src="https://i.ibb.co/yd7G9dZ/pruthivi-new-logo.jpg"
                            alt=""
                            className="w-32 cursor-pointer pr-12"
                          />
                        </a>
                      </span>
                    </div>
                    {/* <button className="ml-20" onClick={handleTalkToSalesClick}>
                  <img src={phonecallnew} className="w-5 h-5" />
                </button> */}
                    <button
                      className="cursor-pointer text-xl leading-none px-3 py-1 border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                      type="button"
                      onClick={() => setNavbarOpen(!navbarOpen)}
                    >
                      {navbarOpen ? (
                        <i className=""></i>
                      ) : (
                        <i className="fas fa-bars"></i>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div></div>
      </nav>
    </section>
  );
}
