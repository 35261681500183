import React from "react";
import AccordionFooter from "./AccordionFooter";

export default function Footer() {
  const accordionData = [
    {
      title: "Projects",
      content: [
        <ul key="list">
          <li className="text-xs font py-2">
            <a href="/project-page1">Pruthvi Assets</a>
          </li>
          <li className="text-xs font py-2">
            <a href="/project-page">Pruthvi Homes</a>
          </li>
          <li className="text-xs font py-2">
            <a href="project-page3">Pruthvi Prime</a>
          </li>
          <li className="text-xs font py-2">
            <a href="/project-page2">Pruthvi Paradise</a>
          </li>
        </ul>,
      ],
    },
    {
      title: "Quick Menu",
      content: [
        <ul key="list">
          
         
          <li className="text-xs font py-2">
            <a href="/">Home</a>
          </li>
          <li className="text-xs font py-2">
            <a href="/aboutt-us">About Us</a>
          </li>
          <li className="text-xs font py-2">
            <a href="/contact">Enquiry Us</a>
          </li>
          <li className="text-xs font py-2">
            <a href="/contact">Contact Us</a>
          </li>
        </ul>,
      ],
    },
  ];

  return (
    <>
      <footer className="bg-blue-900 pt-8 pb-6 ">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        ></div>
        <div className="container mx-auto xl:px-16 xl:pt-10 px-4 xl:block lg:hidden sm:hidden md:hidden hidden">
          <div className="justify-between flex">
            <div>
              <a>
                <p className="font text-white xl:text-4xl">Let's Talk</p>
              </a>
            </div>
            <div className="xl:block md:hidden lg:block hidden">
              <a href="/">
                <button
                  className="bg-white text-color shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-lg outline-none focus:outline-none mr-2"
                  type="button"
                  href="youtube"
                >
                  <i className="fab fa-facebook"></i>
                </button>
              </a>
              <a href="/">
                <button
                  className="bg-white text-color shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-lg outline-none focus:outline-none mr-2"
                  type="button"
                  href="twitter"
                >
                  <i className="">𝕏</i>
                </button>
              </a>

              {/* <button
                className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-lg outline-none focus:outline-none mr-2"
                type="button"
                href="instgram"
              >
                <i class="fab fa-instagram"></i>
              </button> */}

              {/* <button
                className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-lg outline-none focus:outline-none mr-2"
                type="button"
                href="Google"
              >
                <i class="fab fa-google-plus"></i>
              </button> */}
              <a href="/">
                <button
                  className="bg-white text-color shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-lg outline-none focus:outline-none mr-2"
                  type="button"
                  href="youtube"
                >
                  <i className="fab fa-youtube"></i>
                </button>
              </a>
              <a href="/">
                <button
                  className="bg-white text-color shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-lg outline-none focus:outline-none mr-2"
                  type="button"
                  href="youtube"
                >
                  <i className="fab fa-linkedin"></i>
                </button>
              </a>
            </div>
          </div>
          <div className="flex flex-wrap lg:text-left xl:pt-8 pt-8">
            <div className="w-full xl:w-6/12 lg:w-6/12">
              <h4 className="xl:text-xl text-white md:text-2xl text-xl font-semibold">
               Projects
              </h4>
              <div className="ftDropDown"></div>
              <div className="grid xl:grid-cols-3 md:grid-cols-2 xl:pt-4 pt-4">
                <div>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-white hover:text-red-800 font-semibold block xl:pb-2 md:pt-3 xl:pt-0 text-sm"
                        href="/project-page1"
                      >
                        Pruthvi Asset
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-red-800 font-semibold block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-sm"
                        href="/project-page2"
                      >
                        Pruthvi Paradise
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-white hover:text-red-800 font-semibold block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-sm"
                        href="/project-page"
                      >
                        Pruthvi Home
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-red-800 font-semibold block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-sm"
                        href="/project-page3"
                      >
                       Pruthvi Prime
                      </a>
                    </li>
                    {/* <li>
                      <a
                        className="text-white hover:text-red-800 font-semibold block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-sm"
                        href=""
                      >
                        Consulting
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div>
                  <ul className="list-unstyled">
                    {/* <li>
                      <a
                        className="text-white hover:text-red-800 font-semibold block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-sm"
                        href=""
                      >
                        Core Enterprise and zCloud
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-red-800 font-semibold block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-sm"
                        href=""
                      >
                        Network and Edge
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-white hover:text-red-800 font-semibold block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-sm"
                        href=""
                      >
                        Marslab Bridge
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-6/12 lg:w-6/12  xl:pt-0 md:pt-4 pt-8">
              <div className="items-top mb-6">
                <div className="w-full lg:w-8/12 ml-auto">
                  <span className="xl:text-xl text-white md:text-2xl text-xl font-semibold">
                    Quick Menu
                  </span>

                  <div className="ftDropDown"></div>
                  <div className="grid xl:grid-cols-2 md:grid-cols-2 xl:pt-4">
                    <div>
                      <ul className="list-unstyled">
                        <li>
                          <a
                            className="text-white hover:text-red-800 font-semibold block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-sm"
                            href="/aboutt-us"
                          >
                           About Us
                          </a>
                        </li>
                        <li>
                          <a
                            className="text-white hover:text-red-800 font-semibold block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-sm"
                            href="/contact"
                          >
                            Contact Us
                          </a>
                        </li>
                        
                      
                      </ul>
                    </div>

                    <div>
                      <ul className="list-unstyled">
                        <li>
                          <a
                            className="text-white hover:text-red-800 font-semibold block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-sm"
                            href="/"
                          >
                            Home
                          </a>
                        </li>
                        {/* <li>
                          <a
                            className="text-white hover:text-red-800 font-semibold block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-sm"
                            href="contact"
                          >
                            Contact Us
                          </a>
                        </li> */}
                        <li>
                          <a
                            className="text-white hover:text-red-800 font-semibold block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-sm"
                            href="contact"
                          >
                            Enquiry Us
                          </a>
                        </li>
                        {/* <li>
                          <a
                            className="text-white hover:text-red-800 font-semibold block xl:pb-2 md:pt-3 xl:pt-0 pt-4 text-sm"
                            href=""
                          >
                            News
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-white font-semibold py-1">
                Copyright © {new Date().getFullYear()} Pruthvi Inc. All rights
                reserved{" "}
                <a href="" className="text-blueGray-500 hover:text-red-800"></a>
                .
              </div>
            </div>
          </div>
        </div>
        <div className="xl:hidden md:block block">
          <div className="app px-4">
            {accordionData.map((item, index) => (
              <AccordionFooter
                key={index}
                title={item.title}
                content={item.content}
              />
            ))}
          </div>
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 pt-8 mx-auto text-center">
              <div className="text-sm text-white font-semibold py-1">
                Copyright © {new Date().getFullYear()} Pruthvi Inc. All rights
                reserved{" "}
                <a href="" className="text-blueGray-500 hover:text-red-800"></a>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
